import { useState, useEffect, useRef, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faCheck } from '@fortawesome/free-solid-svg-icons'
import { useTimer } from 'react-timer-hook';
import { CheckIcon } from '@heroicons/react/solid'
import { Listbox, Transition } from '@headlessui/react'
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, array, string, bool } from "yup";
import { useTranslation } from 'react-i18next';
import { padStart, get, orderBy, uniq, compact, isEmpty, head } from 'lodash'
import axios from 'axios'
import { subMilliseconds, format, addMonths, differenceInMinutes, addMilliseconds } from 'date-fns'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { ArrowUpIcon } from '@heroicons/react/outline'
import { useOutletContext } from "react-router-dom";
import qs from 'qs'
import Cookies from 'universal-cookie';

import DatePicker from './DatePicker'
import ErrorMessage from './ErrorMessage'
import HeadsUp from './HeadsUp'
import { sessionFilter } from '../utils/common'
import { Link } from 'react-router-dom';
import { usePrompt } from './UsePrompt';
import { ReCaptcha } from './ReCaptcha';

import Alert from './Alert';
import Loading from './Loading';
import { trackGa, trackGaEvt, trackGaErr } from '../utils/ga'
import { getTimezoneOffset, zonedTimeToUtc, formatInTimeZone } from 'date-fns-tz'

const MAX_PARTICIPANTS = 3;
const AGE_GROUP_DEFAULT_VAL = 'ADULT';
const LOCK_INTERVAL = 600; // 10 minutes
const HEADS_UP_INTERVAL = 480; // 8 minutes (reached 2 minutes = heads up notification)
const ALERT_DISPLAY_TIME_IN_MS = 5000;

const lockTimer = new Date();
lockTimer.setSeconds(lockTimer.getSeconds() + LOCK_INTERVAL);

const headsUpTimer = new Date();
headsUpTimer.setSeconds(headsUpTimer.getSeconds() + HEADS_UP_INTERVAL);

// stage 1: SLOT
// stage 2: INFO
// stage 3: REVIEW
// stage 4: DONE

const PASS_PHRASE_LENGTH = 6;
const PHONE_NUMBER_LENGTH = 8
const ID_SECOND_SEC_LENGTH = 3;
const ID_FIRST_SEC_LENGTH = 1;
const ID_LENGTH = 4;
const SESSION_WITHIN_MONTHS = 2;
// // Add method
// addMethod(string, 'stringToNumber', function () {
//   return this.transform((value) => parseInt(value));
// });

const phoneRegex = /^[1|4-9][0-9]+$/;

const convertDateToZonedTime = (date) => {
    const zonedDate = zonedTimeToUtc(date, Intl.DateTimeFormat().resolvedOptions().timeZone)
    const offset = getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone);
    if (offset > 0) {
        return addMilliseconds(zonedDate, offset / 2); 
    } else {
        return subMilliseconds(zonedDate, offset); 
    }
}

const getBookingSchema = () =>
    object().shape({
        'hkidAlphabet': string().when('idType', {
            is: 'HKID',
            then: string().required().length(ID_FIRST_SEC_LENGTH).trim().transform((currentValue) => currentValue.toUpperCase()).matches(/^[A-Z]$/),
            otherwise: string().nullable(true).transform((v, o) => null)
        }),
        'hkidNumber': string().when('idType', {
            is: 'HKID',
            then: string().required().matches(/^[0-9]+$/).test('len', 'validations.idLen', val => !val || (val && val.length === ID_SECOND_SEC_LENGTH)),
            otherwise: string().nullable(true).transform((v, o) => null)
        }),
        'travelDocumentId': string().when('idType', {
            is: 'TRAVEL_DOCUMENT_ID',
            then: string().required().length(ID_LENGTH).trim().transform((currentValue) => currentValue.toUpperCase()).matches(/^[A-Z0-9]+$/),
            otherwise: string().nullable(true).transform((v, o) => null)
        }),
        'phoneNumber': string().required().matches(/^[0-9]+$/).test('len', 'validations.phoneLen', val => !val || (val && val.toString().length === PHONE_NUMBER_LENGTH)).test('format', 'validations.phoneFormat', val => !val || (val && phoneRegex.test(val.toString()))),
        'passphrase': string().required().matches(/^[0-9]+$/).test('len', 'validations.passLen', val => !val || (val && val.toString().length === PASS_PHRASE_LENGTH)),  // TODO unique
        'declare': bool().oneOf([true], 'validations.oneOf'),
        'tc1': bool().oneOf([true], 'validations.oneOf'),
        'tc2': bool().oneOf([true], 'validations.oneOf'),
        'tc3': bool().oneOf([true], 'validations.oneOf'),
        // 'tc4': bool().oneOf([true], 'validations.oneOf'),
        'tc5': bool().oneOf([true], 'validations.oneOf'),
        'tc6': bool().oneOf([true], 'validations.oneOf'),
        // 'hasAgreeEmailPromotion': bool().default(false),
        'idType': string().required().oneOf(['TRAVEL_DOCUMENT_ID', 'HKID']),
        participants: array()
            .of(
                object().shape({
                    'hkidAlphabet': string().when('idType', {
                        is: 'HKID',
                        then: string().required().length(ID_FIRST_SEC_LENGTH).trim().transform((currentValue) => currentValue.toUpperCase()).matches(/^[A-Z]$/),
                        otherwise: string().nullable(true).transform((v, o) => null)
                    }),
                    'hkidNumber': string().when('idType', {
                        is: 'HKID',
                        then: string().required().matches(/^[0-9]+$/).test('len', 'validations.idLen', val => !val || (val && val.toString().length === ID_SECOND_SEC_LENGTH)),
                        otherwise: string().nullable(true).transform((v, o) => null)
                    }),
                    'travelDocumentId': string().when('idType', {
                        is: 'TRAVEL_DOCUMENT_ID',
                        then: string().required().length(ID_LENGTH).trim().transform((currentValue) => currentValue.toUpperCase()).matches(/^[A-Z0-9]+$/),
                        otherwise: string().nullable(true).transform((v, o) => null)
                    }),
                    'ageGroup': string().required(),
                    'idType': string().required().oneOf(['TRAVEL_DOCUMENT_ID', 'HKID']),
                    'phoneNumber': string().when("ageGroup", (ageGroupValue) => {
                        if (ageGroupValue === 'ADULT') {
                            return string().required().matches(/^[0-9]+$/).test('len', 'validations.phoneLen', val => !val || (val && val.toString().length === PHONE_NUMBER_LENGTH)).test('format', 'validations.phoneFormat', val => !val || (val && phoneRegex.test(val.toString())))
                        } else {
                            return string().when("phoneNumber", (phoneValue) => {
                                if (!phoneValue && ageGroupValue === 'CHILD') {
                                    return string().nullable(true).transform((v, o) => null)
                                } else if (ageGroupValue === 'CHILD') {
                                    return string().matches(/^[0-9]+$/).test('len', 'validations.phoneLen', val => !val || (val && val.toString().length === PHONE_NUMBER_LENGTH)).test('format', 'validations.phoneFormat', val => !val || (val && phoneRegex.test(val.toString())))
                                }
                            })
                        }
                    })
                }, [
                    [
                        'phoneNumber', 'ageGroup', 'phoneNumber'  // cyclic
                    ]
                ])
            )
    })

export default function Booking(props) {
    const journeyStartAt = new Date();
    const captchaRef = useRef();
    const [setTitle, selectedSession, setSelectedSession] = useOutletContext(); // selectedSession = session actually locked
    const { t, i18n } = useTranslation(['booking', 'errorMessage', 'page']);

    useEffect(() => {
        document.title = t(props.title, { ns: 'page' });
        document.documentElement.lang = i18n.language === 'en' ? 'en' : i18n.language === 'tc' ? 'zh-HK' : 'zh-CN';
        document.getElementsByTagName('meta')["keywords"].content = t('meta-keywords', { ns: 'page' });
        document.getElementsByTagName('meta')["description"].content = t('meta-desc', { ns: 'page' });
        setTitle(document.title);

        trackGa(i18n);
    }, [i18n.language])

    const [stage, setStage] = useState('SLOT');
    const [isFetching, setIsFetching] = useState(false);

    // choosed but not yet proceed
    const [sessionGoingToSelect, setSessionGoingToSelect] = useState(null);

    const [selectedDate, setSelectedDate] = useState(null);
    const [lockData, setLockData] = useState(null);
    const [sessions, setSessions] = useState([]);
    const [selectables, setSelectables] = useState([]);

    const [alertStatus, setAlertStatus] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [groupId, setGroupId] = useState('');


    const [isOpen, setIsOpen] = useState(false);
    const [isTimesup, setIsTimesup] = useState(false);
    const [isHeadsup, setIsHeadsup] = useState(false);

    const [duplicatedPhones, setDuplicatedPhones] = useState([])
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const [data, setData] = useState(null);

    // may not be working for some browser
    usePrompt(t('misc.prompt-alert', { ns: 'booking' }), !!selectedSession, () => {
        // no further action as client already gone
    });

    // Top: 0 takes us all the way back to the top of the page
    // Behavior: smooth keeps it smooth!
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        async function getSessions() {
            const query = qs.stringify({
                _where: [{ startAt_gte: new Date() }, { startAt_lte: addMonths(new Date(), SESSION_WITHIN_MONTHS) }],
            });
            try {
                const response = await axios.get(`${process.env.REACT_APP_STRAPI_DOMAIN}/sessions-all?${query}`);
                if (response.data) {
                    const data = response.data.map(session => {
                        return {
                            ...session,
                            zonedDisplayTime: convertDateToZonedTime(new Date(session.startAt))
                        }
                    })
                    setSessions(data);
                }
            } catch (error) {
                displayAlert(error);
                trackGaErr(error);
            }
        }
        getSessions();
        scrollToTop();
    }, []);

    useEffect(() => {
        if (selectedDate) {

            const matchedSessions = sessions.filter(session => {
                return formatInTimeZone(session.zonedDisplayTime, 'Asia/Hong_Kong', 'yyyyMMdd') === format(new Date(selectedDate), 'yyyyMMdd')
            })

            console.log(matchedSessions)

            if (matchedSessions.length) {
                const formatted = matchedSessions.map(session => {
                    return {
                        ...session,
                        displayStartAt: formatInTimeZone(new Date(session.startAt), 'Asia/Hong_Kong', 'HH:mm'),
                        displayEndAt: formatInTimeZone(new Date(session.endAt), 'Asia/Hong_Kong', 'HH:mm'),
                    }
                })

                setSelectables(orderBy(formatted, ['startAt'], ['asc']))
            } else {
                setSelectables([])
            }


            trackGaEvt('Booking Event', `Calendar Date Selected`, formatInTimeZone(selectedDate, 'Asia/Hong_Kong', 'yyyyMMdd'));
        }
    }, [selectedDate]);

    useEffect(() => {
        if (stage === 'SLOT') {

            const x = new Date();
            x.setSeconds(x.getSeconds() + HEADS_UP_INTERVAL);

            const y = new Date();
            y.setSeconds(y.getSeconds() + LOCK_INTERVAL);

            restartHeadsupTimer(x, false);
            restartLockTimer(y, false);
        }
    }, [stage]);

    const displayAlert = (error) => {
        console.error(error);
        const errMsg = error?.response?.data?.message;
        if (errMsg) {
            setAlertStatus('FAILED');
            setAlertText(t([errMsg, 'unspecific'], { ns: 'errorMessage' }));
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setAlertText('');
            }, ALERT_DISPLAY_TIME_IN_MS)
        }
    }

    const checkPhoneDuplicated = (phoneNumber) => {
        const participantPhones = getValues('participants').map(x => x.phoneNumber);
        const applicantPhone = getValues('phoneNumber')
        const combined = compact([...participantPhones, applicantPhone]);
        const res = combined.filter(x => {
            return x === phoneNumber
        })

        if (isSubmitted && res.length > 1 && !duplicatedPhones.includes(phoneNumber)) {
            setDuplicatedPhones(uniq([
                ...duplicatedPhones,
                phoneNumber
            ]));
        } else if (isSubmitted && res.length <= 1 && duplicatedPhones.includes(phoneNumber)) {
            const newV = duplicatedPhones.filter(x => x !== phoneNumber);
            setDuplicatedPhones(newV);
        }

        return res.length > 1 && isSubmitted;
    }

    const {
        register,
        handleSubmit,
        control,
        trigger,
        formState: { errors, isSubmitted },
        getValues,
        reset
    } = useForm({
        reValidateMode: 'onChange',
        resolver: yupResolver(getBookingSchema()),
        defaultValues: {
            participants: []
        },
    });

    const participants = useWatch({
        control,
        name: "participants",
    });

    const { fields, append, remove } = useFieldArray({
        name: "participants",
        control
    });

    const {
        seconds,
        minutes,
        start: startLockTimer,
        restart: restartLockTimer,
        isRunning: isTimerRunning
    } = useTimer({
        expiryTimestamp: lockTimer,
        autoStart: false,
        onExpire: async () => {
            trackGaEvt('Booking Event', `Timer Expired`);
            await resetForm();
            setIsTimesup(true);
            setIsHeadsup(false);
            setIsOpen(true);
        }
    });

    const {
        minutes: headsUpMinutes,
        start: startHeadsupTimer,
        restart: restartHeadsupTimer
    } = useTimer({
        expiryTimestamp: headsUpTimer,
        autoStart: false,
        onExpire: () => {
            trackGaEvt('Booking Event', `Headsup Timer Reached`);
            setIsTimesup(false);
            setIsHeadsup(true);
            setIsOpen(true);
        }
    });

    const cleanupWithStage = (stage) => {
        const x = new Date();
        x.setSeconds(x.getSeconds() + HEADS_UP_INTERVAL);

        const y = new Date();
        y.setSeconds(y.getSeconds() + LOCK_INTERVAL);

        reset();
        restartHeadsupTimer(x, false);
        restartLockTimer(y, false);
        setSelectedSession(null);
        setSessionGoingToSelect(null);
        setSelectedDate(null);
        setLockData(null);
        setStage(stage)
        setRecaptchaVerified(false);
        scrollToTop();

        // attempt to remove cookies so that if user completed the booking form, he/she should be forced to leave
        if (stage === 'DONE') {
            try {
                const cookies = new Cookies();
                cookies.remove('__waiting_room_id');
                cookies.remove('__waiting_room_candidate');
                cookies.remove('__waiting_room_last_update_time');
            } catch (error) {
                console.error('[COOKIES CLEAN UP]', error);
            }
        }
    }

    const totalParticipants = fields.length + 1;

    const triggerValidation = (e) => {
        // disabled on 09-18 due to user request
        // trigger();
    }

    const occupySession = async () => {
        if (isFetching) return;
        if (!sessionGoingToSelect) return;
        if (sessionGoingToSelect.numberOfAvailableSlot <= 0) return;
        setIsFetching(true);
        async function doLockActions() {
            try {
                const lockRes = await axios.post(`${process.env.REACT_APP_STRAPI_DOMAIN}/occupiers/lock-session`, {
                    session: sessionGoingToSelect.id,
                    numberOfParticipant: totalParticipants
                })
                if (lockRes) {
                    trackGaEvt('Booking Event', `Session Selected & Locked`, formatInTimeZone(new Date(sessionGoingToSelect.startAt), 'Asia/Hong_Kong', 'yyyyMMddHHmm'));
                    startLockTimer();
                    startHeadsupTimer();
                    setSelectedSession(sessionGoingToSelect)
                    setLockData(lockRes.data)
                    setStage('INFO');
                    scrollToTop();
                }
            } catch (error) {
                displayAlert(error);
                trackGaErr(error);
            }
            setIsFetching(false)
            return null;
        }
        doLockActions();
    }

    const resetForm = async () => {
        if (isFetching) return;
        async function releaseLock() {
            setIsFetching(true);
            try {
                await axios.post(`${process.env.REACT_APP_STRAPI_DOMAIN}/occupiers/release-lock`, {
                    session: selectedSession.id,
                    lockId: lockData.lockId
                })
                trackGaEvt('Booking Event', `Dropout From Selected Session (Lock Released)`, formatInTimeZone(new Date(selectedSession.startAt), 'Asia/Hong_Kong', 'yyyyMMddHHmm'));
            } catch (error) {
                displayAlert(error);
                trackGaErr(error);
            }
            setIsFetching(false);
            return null;
        }
        await releaseLock();
        cleanupWithStage('SLOT');
    }

    const completeForm = () => {
        async function submitData() {
            try {

                const participantsData = data.participants.map(x => {
                    return {
                        ...x,
                        phoneNumber: x.phoneNumber ? x.phoneNumber.toString() : ''
                    }
                })

                const resp = await axios.post(`${process.env.REACT_APP_STRAPI_DOMAIN}/occupiers/submit-data`, {
                    info: {
                        ...data,
                        phoneNumber: data.phoneNumber.toString(),
                        passphrase: data.passphrase.toString(),
                        participants: participantsData
                    },
                    lang: get(i18n, 'language', 'en'),
                    session: selectedSession.id,
                    lockId: lockData.lockId
                })

                if (resp) {
                    trackGaEvt('Booking Event', `Booking Completed Within Minutes`, differenceInMinutes(new Date(), journeyStartAt));
                    setGroupId(get(resp, 'data.0.groupId', ''));
                }

                cleanupWithStage('DONE');

            } catch (error) {
                displayAlert(error);
                trackGaErr(error);
            }
            setIsFetching(false);
            return null;
        }

        if (stage === 'REVIEW') {
            if (isFetching) return;
            setIsFetching(true);
            submitData();
        }
    }

    const onSubmitForm = (data) => {
        if (isEmpty(errors) && recaptchaVerified && !duplicatedPhones.length) {
            setData(data);
            setStage('REVIEW')
            trackGaEvt('Booking Event', `Data Input Completed Within Minutes`, differenceInMinutes(new Date(), journeyStartAt));
            scrollToTop();
        }
    };

    const addNop = () => {
        let newValue = fields.length;
        newValue++;
        if (newValue > MAX_PARTICIPANTS || newValue < 0) {
            return;
        }

        append({
            ageGroup: AGE_GROUP_DEFAULT_VAL
        });
    }

    const deductNop = () => {
        let newValue = fields.length;
        newValue--;
        if (newValue > MAX_PARTICIPANTS || newValue < 0) {
            return;
        }

        remove(fields.length - 1);
    }

    const setStageToInfo = () => {
        setRecaptchaVerified(false);
        reset({}, { keepValues: true, keepDefaultValues: true, keepIsSubmitted: true })
        setStage('INFO');
        trackGaEvt('Booking Event', `Go Back To Data Input`);
        scrollToTop();
    }

    return <div className="w-full bg-white">
        <HeadsUp
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isTimesup={isTimesup}
            isHeadsup={isHeadsup}
            setIsTimesup={setIsTimesup}
        />
        <Alert status={alertStatus} text={alertText} setShow={setShowAlert} show={showAlert} />
        {isFetching && <Loading />}
        <form onSubmit={handleSubmit(onSubmitForm)}>
            {stage === 'SLOT' && <div className="flex justify-start bg-blue-50 bg-opacity-60 py-2 px-4 mt-4">
                <div className="flex flex-none items-center px-1">
                    <div className="px-3 py-1 border-1 rounded-full bg-wsd-form text-white text-xl font-bold">
                        1
                    </div>
                    <div className="flex mx-6">
                        <a href="/#method"><p className="inline-block text-wsd-form font-bold text-xl">{t('section-1.title', { ns: 'booking' })}</p></a>
                    </div>
                </div>
            </div>}

            {isTimerRunning && <div className="z-20 flex w-[160px] ml-auto sticky items-center top-20 -mt-10 h-10">
                <div className="bg-wsd rounded-l-full py-3 px-1">
                    <div className="flex flex-col ml-2 px-4">
                        <span className={`inline-block text-white font-bold ${i18n.language === 'en' ? 'text-sm' : 'text-lg'}`}>{t('common.timer', { ns: 'booking' })}</span>
                        <span className="inline-block text-white font-bold text-xl">{padStart(minutes, 2, '0')}:{padStart(seconds, 2, '0')}</span>
                    </div>
                </div>
            </div>}

            {stage === 'SLOT' &&
                <div>

                    <div className="lg:flex lg:justify-center lg:py-8">
                        <div className="flex justify-center py-2 px-4 mt-3">
                            <div className="flex flex-none items-center px-1">
                                <div className="flex mx-6">
                                    <p className="inline-block text-wsd font-bold text-xl lg:text-2xl lg:mb-2">{t('section-1.nop', { ns: 'booking' })}</p>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-center py-2 px-4 lg:border-r-2 lg:pr-16">
                            <div className="flex flex-none items-center px-1">
                                <div className="drop-shadow-md">
                                    <div className="w-12 h-12 bg-white rounded-lg text-wsd font-semibold text-4xl flex justify-center items-center">
                                        {totalParticipants}
                                    </div>
                                </div>
                                <div className="flex mx-3">
                                    <p className="inline-block text-wsd font-bold text-2xl">{t('section-1.measure-word', { ns: 'booking', count: totalParticipants })}</p>
                                </div>
                                <div className="flex ml-5">
                                    <div tabIndex="1" className="cursor-pointer mr-2 px-3 py-1 rounded-lg bg-wsd text-white font-bold focus:outline-none focus:ring-2 focus:ring-yellow-500" onClick={() => addNop()}>
                                        <FontAwesomeIcon icon={faPlus} alt="Add The Number of Participant" />
                                    </div>
                                    <div tabIndex="2" className="cursor-pointer px-3 py-1 rounded-lg bg-wsd text-white font-bold focus:outline-none focus:ring-2 focus:ring-yellow-500" onClick={() => deductNop()}>
                                        <FontAwesomeIcon icon={faMinus} alt="Deduct The Number of Participant" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="bg-gray-300 h-0.5 mx-6 mt-6" />

                        <div className="flex justify-start mx-6">
                            <div className="flex flex-col py-4">
                                <p className="inline-block text-wsd-form font-bold text-xl">{t('section-1.tips-1', { ns: 'booking' })}</p>
                                <ol className="mt-2 text-gray-600 text-sm break-all list-disc list-inside">
                                    <li className="p-1">{t('section-1.tips-2', { ns: 'booking' })}</li>
                                    <li className="p-1">{t('section-1.tips-3', { ns: 'booking' })}</li>
                                    <li className="p-1">{t('section-1.tips-4', { ns: 'booking' })}</li>
                                    <li className="p-1">{t('section-1.tips-5', { ns: 'booking' })}</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-start bg-blue-50 bg-opacity-60 py-2 px-4 mt-4">
                        <div className="flex flex-none items-center px-1">
                            <div className="px-3 py-1 border-1 rounded-full bg-wsd-form text-white text-xl font-bold">
                                2
                            </div>
                            <div className="flex mx-6">
                                <p className="inline-block text-wsd-form font-bold text-xl">{t('section-2.title', { ns: 'booking' })}</p>
                            </div>
                        </div>
                    </div>

                    <div className="lg:flex lg:justify-center lg:mt-8">
                        <div className="flex justify-center py-2 px-4 mt-6 lg:mt-2">
                            <div className="flex flex-none items-center px-1">
                                <div className="flex mx-6">
                                    <label for="date" className="inline-block text-wsd font-bold text-xl">{t('section-2.visit-date', { ns: 'booking' })}</label>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-center py-2 px-4 lg:w-1/2 lg:max-w-xl">
                            <div className="relative w-full">
                                <DatePicker
                                    placeHolder={t('section-2.select-date', { ns: 'booking' })}
                                    sessions={sessions}
                                    numberOfParticipant={totalParticipants}
                                    setSelectedDate={setSelectedDate}
                                    setSessionGoingToSelect={setSessionGoingToSelect}
                                    selectedDate={selectedDate}
                                />
                                <div className="absolute top-3 right-5">
                                    <img className="w-8" src="/img/ic-calendar.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="lg:flex lg:justify-center lg:mt-8">
                        <div className="flex justify-center py-2 px-4 mt-6 lg:mt-2">
                            <div className="flex flex-none items-center px-1">
                                <div className="flex mx-6">
                                    <p className="inline-block text-wsd font-bold text-lg">{t('section-2.visit-period', { ns: 'booking' })}</p>
                                </div>
                            </div>
                        </div>


                        <div className="flex justify-center py-2 px-4 lg:w-1/2 lg:max-w-xl">
                            <div className="relative w-full">
                                <Listbox value={sessionGoingToSelect} onChange={(selected) => setSessionGoingToSelect(selected)}>
                                    <div className="relative mt-1">
                                        <Listbox.Button className="text-left text-lg shadow-md focus:outline-none focus:ring-2 focus:ring-yellow-500 block w-full h-14 p-4 rounded-lg">
                                            <span className={`${sessionGoingToSelect ? 'text-gray-900' : 'text-gray-500'} block truncate`}>{sessionGoingToSelect ? `${sessionGoingToSelect.displayStartAt} - ${sessionGoingToSelect.displayEndAt}` : t('section-2.select-one-of-the-period', { ns: 'booking' })}</span>
                                            <span className="pointer-events-none absolute inset-y-0 right-4 flex items-center pr-2">
                                                <img className="w-8" src="/img/ic-dropdown.png" alt="" />
                                            </span>
                                        </Listbox.Button>
                                        <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {selectables.map((selectable, idx) => (
                                                    <Listbox.Option
                                                        key={idx}
                                                        disabled={selectable.numberOfAvailableSlot <= 0}
                                                        className={({ active }) =>
                                                            `${selectable.numberOfAvailableSlot <= 0 ? 'bg-gray-500 text-gray-300' : active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'} relative cursor-default select-none py-2 pl-10 pr-4`
                                                        }
                                                        value={selectable}
                                                    >
                                                        {({ listboxSelected }) => (
                                                            <>
                                                                <span
                                                                    className={`block truncate ${listboxSelected ? 'font-medium' : 'font-normal'
                                                                        }`}
                                                                >
                                                                    {selectable.displayStartAt} - {selectable.displayEndAt} {selectable.numberOfAvailableSlot === 0 ? t('section-2.full', { ns: 'booking' }) : ''}
                                                                </span>
                                                                {listboxSelected ? (
                                                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </Listbox>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-start lg:justify-center mx-6">
                        <div className="flex flex-col py-4">
                            <span className="inline-block text-gray-600 text-sm py-1 pt-3">{t('section-2.hint', { ns: 'booking' })}</span>
                        </div>
                    </div>

                    <div className="flex justify-start lg:justify-center mx-6 mt-4">
                        <div className="mt-6 lg:max-w-[400px] w-full mx-4">
                            <button type="button" onClick={() => occupySession()} disabled={!sessionGoingToSelect || get(sessionGoingToSelect, 'numberOfAvailableSlot', 0) <= 0} className={`${!sessionGoingToSelect || get(sessionGoingToSelect, 'numberOfAvailableSlot', 0) <= 0 ? 'cursor-not-allowed bg-gray-500 hover:bg-gray-700' : 'bg-wsd hover:bg-blue-700'} h-12 w-full p-2 text-xl rounded-full text-white font-semibold shadow-md focus:outline-none focus:ring-4 focus:ring-yellow-500`}>
                                {t('section-2.next', { ns: 'booking' })}
                            </button>
                        </div>
                    </div>

                </div>}

            {stage === 'INFO' && <div id="main-input">
                <div className="flex justify-start bg-blue-50 bg-opacity-60 py-2 px-4 mt-4">
                    <div className="flex flex-none items-center px-1">
                        <div className="px-3 py-1 border-1 rounded-full bg-wsd-form text-white text-xl font-bold">
                            3
                        </div>
                        <div className="flex mx-6">
                            <p className="inline-block text-wsd-form font-bold text-xl">{t('section-3.title', { ns: 'booking' })}</p>
                        </div>
                    </div>
                </div>

                <div className="text-lg lg:flex lg:justify-center lg:mt-4">
                    <div className="lg:w-1/2 flex justify-center mx-6 mt-4 lg:pr-16">
                        <div className="flex flex-col text-justify py-4 text-md">
                            <span className="inline-block text-gray-600 text-semibold py-1 pt-3">{t('section-3.reminder', { ns: 'booking' })}</span>
                        </div>
                    </div>
                </div>

                <div className="lg:flex lg:justify-center lg:mt-8">
                    <div className="lg:w-[230px] flex justify-center mx-6 mt-4 lg:border-r-2 lg:pr-16">
                        <div className="flex flex-col text-center py-4">
                            <span className="inline-block text-wsd font-bold text-xl mt-4">{t('section-3.p1', { ns: 'booking' })}</span>
                            <span className="inline-block text-gray-600 text-semibold py-1 pt-3">{t('section-3.p1-desc', { ns: 'booking' })}</span>
                            <span className="inline-block text-gray-500 text-normal text-sm py-1 pt-3">{t('section-3.p1-desc-additional', { ns: 'booking' })}</span>
                            <span className="inline-block text-wsd font-bold text-xl border-t-2 pt-4 lg:mt-10 mt-4">{t('section-3.selected-period', { ns: 'booking' })}</span>
                            <span className="inline-block text-gray-600 text-semibold py-1 pt-3">{t('section-3.date', { ns: 'booking' })} : {formatInTimeZone(new Date(selectedSession.startAt), 'Asia/Hong_Kong', 'yyyy - MM - dd')}</span>
                            <span className="inline-block text-gray-600 text-semibold py-1 pt-1 lg:border-b-2 lg:pb-4">{t('section-3.period', { ns: 'booking' })} : {formatInTimeZone(new Date(selectedSession.startAt), 'Asia/Hong_Kong', 'HH:mm')} - {formatInTimeZone(new Date(selectedSession.endAt), 'Asia/Hong_Kong', 'HH:mm')}</span>
                        </div>
                    </div>

                    <hr className="bg-gray-300 h-0.5 mx-6" />

                    <div className="mx-6 lg:w-1/3 lg:max-w-xl">
                        <div className="mt-8">
                            <div className="mt-10 lg:max-w-xl ">
                                <div className="relative flex items-start mt-6">
                                    <div className="ml-1 flex items-center h-5">
                                        <input {...register(`declare`)} id="declare" type="checkbox" className="mt-1 border-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                    </div>
                                    <div className="ml-3 text-md font-medium">
                                        <label for="declare" className="text-gray-600">{t('section-3.checkbox', { ns: 'booking' })}</label>
                                        {errors['declare'] && <ErrorMessage error={errors['declare']?.message} />}
                                    </div>
                                </div>
                            </div>

                            <div className="mt-10 lg:max-w-xl ">
                                <label className="ml-1 block text-md font-medium text-wsd">{t('section-3.id', { ns: 'booking' })}</label>
                                <div className="mt-3 ml-1">
                                    <div className="flex items-center space-y-0 space-x-10">
                                        <div className="flex items-center">
                                            <input id="hkid" {...register("idType")} checked={getValues('idType') === 'HKID'} value="HKID" type="radio" className="border-2 focus:outline-none focus:ring-2 focus:ring-yellow-500 h-4 w-4 min-w-[14px] text-wsd border-gray-300" />
                                            <label for="hkid" className="ml-3 block text-md font-medium text-gray-600">{t('section-3.hkid', { ns: 'booking' })}</label>
                                        </div>

                                        <div className="flex items-center">
                                            <input id="travelDocumentId" {...register("idType")} checked={getValues('idType') === 'TRAVEL_DOCUMENT_ID'} value="TRAVEL_DOCUMENT_ID" type="radio" className="border-2 focus:outline-none focus:ring-2 focus:ring-yellow-500 h-4 w-4 min-w-[14px] text-wsd border-gray-300" />
                                            <label for="travelDocumentId" className="ml-3 block text-md font-medium text-gray-600">{t('section-3.travel-doc', { ns: 'booking' })}</label>

                                        </div>
                                    </div>
                                    <div>
                                        {errors['idType'] && <ErrorMessage error={errors['idType']?.message} />}
                                    </div>
                                </div>
                            </div>

                            {getValues('idType') === 'HKID' && <div className="mt-10">
                                <label for="hkidAlphabet" className="ml-1 block text-md font-medium text-wsd">{t('section-3.hkid-no', { ns: 'booking' })}</label>
                                <label for="hkidNumber" className="ml-1 block text-sm text-gray-500">{t('section-3.first-4-id', { ns: 'booking' })}</label>
                                <div className="mt-3">
                                    <div className="mt-6 grid gap-y-6 gap-x-4 grid-cols-6 lg:max-w-md ">
                                        <div className="col-span-2">
                                            <div className="mt-1">
                                                <input id="hkidAlphabet" {...register("hkidAlphabet")} type="text" className={`${errors['hkidNumber'] ? 'outline-none ring-2 ring-red-500' : 'focus:outline-none focus:ring-2 focus:ring-yellow-500'} text-lg shadow-md block w-full h-14 p-4 rounded-lg`} placeholder={t('section-3.first-digit', { ns: 'booking' })} />
                                                {errors['hkidAlphabet'] && <ErrorMessage error={errors['hkidAlphabet']?.message} />}
                                            </div>
                                        </div>

                                        <div className="col-span-4">
                                            <div className="mt-1">
                                                <input id="hkidNumber" {...register("hkidNumber")} type="text" className={`${errors['hkidNumber'] ? 'outline-none ring-2 ring-red-500' : 'focus:outline-none focus:ring-2 focus:ring-yellow-500'} text-lg shadow-md block w-full h-14 p-4 rounded-lg`} placeholder={t('section-3.next-3-digit', { ns: 'booking' })} />
                                                {errors['hkidNumber'] && <ErrorMessage error={errors['hkidNumber']?.message} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            {getValues('idType') === 'TRAVEL_DOCUMENT_ID' && <div className="mt-10">
                                <label for="travelDocumentId" className="ml-1 block text-md font-medium text-wsd">{t('section-3.travel-doc-no', { ns: 'booking' })}</label>
                                <label className="ml-1 block text-sm text-gray-500">{t('section-3.first-4-id', { ns: 'booking' })}</label>
                                <div className="mt-3">
                                    <div className="mt-6 grid gap-y-6 gap-x-4 grid-cols-6 lg:max-w-md ">
                                        <div className="col-span-6">
                                            <div className="mt-1">
                                                <input id="travelDocumentId" {...register("travelDocumentId")} type="text" className={`${errors['travelDocumentId'] ? 'outline-none ring-2 ring-red-500' : 'focus:outline-none focus:ring-2 focus:ring-yellow-500'} text-lg shadow-md block w-full h-14 p-4 rounded-lg`} placeholder={t('section-3.first-4-digit', { ns: 'booking' })} />
                                                {errors['travelDocumentId'] && <ErrorMessage error={errors['travelDocumentId']?.message} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            <div className="mt-10">
                                <label for="phoneNumber" className="ml-1 block text-md font-medium text-wsd">{t('section-3.phone', { ns: 'booking' })}</label>
                                <label className="ml-1 block text-sm text-gray-500">{t('section-3.enter-phone', { ns: 'booking' })}</label>
                                <label className="ml-1 block text-sm text-gray-500">{t('section-3.phone-hint-1', { ns: 'booking' })}</label>
                                <div className="mt-3">
                                    <input id="phoneNumber" type="text" {...register("phoneNumber")} className={`${errors['phoneNumber'] ? 'outline-none ring-2 ring-red-500' : 'focus:outline-none focus:ring-2 focus:ring-yellow-500'} lg:max-w-xl text-lg shadow-md block w-full h-14 p-4 rounded-lg`} placeholder={t('section-3.enter-phone', { ns: 'booking' })} />
                                    {errors['phoneNumber'] && <ErrorMessage error={errors['phoneNumber']?.message} />}
                                    {checkPhoneDuplicated(getValues('phoneNumber')) && <ErrorMessage error={'validations.phoneDuplicated'} />}
                                </div>
                            </div>

                            <div className="mt-10">
                                <label for="passphrase" className="ml-1 block text-md font-medium text-wsd">{t('section-3.passphrase', { ns: 'booking' })}</label>
                                <label className="ml-1 block text-sm text-gray-500">{t('section-3.passphrase-hint', { ns: 'booking' })}</label>
                                <div className="mt-3">
                                    <input id="passphrase" type="text" {...register("passphrase")} className={`${errors['passphrase'] ? 'outline-none ring-2 ring-red-500' : 'focus:outline-none focus:ring-2 focus:ring-yellow-500'} lg:max-w-xl text-lg shadow-md block w-full h-14 p-4 rounded-lg`} placeholder={t('section-3.enter-passphrase', { ns: 'booking' })} />
                                    {errors['passphrase'] && <ErrorMessage error={errors['passphrase']?.message} />}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {fields.map((field, idx) => {
                    return <div key={field.id} className="lg:flex lg:justify-center lg:mt-8">
                        <div className="lg:w-[230px] flex justify-center mx-6 mt-16  lg:border-r-2 lg:pr-16">
                            <div className="flex flex-col text-center py-4">
                                <span className="inline-block text-wsd font-bold text-xl">{t('section-3.participant', { ns: 'booking' })} {idx + 2}</span>
                            </div>
                        </div>

                        <hr className="bg-gray-300 h-0.5 mx-6" />

                        <div className="mx-6 lg:w-1/3 lg:max-w-xl">
                            <div className="mt-8">
                                <div className="mt-10 lg:max-w-xl ">
                                    <label htmlFor="ageGroup" className="ml-1 block text-md font-medium text-wsd">{t('section-3.ageGroup', { ns: 'booking' })}</label>
                                    <div className="mt-3 ml-1">
                                        <div className="flex items-center space-y-0 space-x-10">
                                            <div className="flex items-center">
                                                <input id="adult" onClick={(e) => triggerValidation(e)} {...register(`participants.${idx}.ageGroup`)} value="ADULT" type="radio" className="border-2 focus:outline-none focus:ring-2 focus:ring-yellow-500 h-4 w-4 min-w-[14px] text-wsd border-gray-300" />
                                                <label for="adult" className="ml-3 block text-md font-medium text-gray-600">{t('section-3.adult', { ns: 'booking' })}</label>
                                            </div>

                                            <div className="flex items-center">
                                                <input id="child" onClick={(e) => triggerValidation(e)} {...register(`participants.${idx}.ageGroup`)} value="CHILD" type="radio" className="border-2 focus:outline-none focus:ring-2 focus:ring-yellow-500 h-4 w-4 min-w-[14px] text-wsd border-gray-300" />
                                                <label for="child" className="ml-3 block text-md font-medium text-gray-600">{t('section-3.child', { ns: 'booking' })}<p className="block text-sm font-medium text-gray-500">{t('section-3.child-desc', { ns: 'booking' })}</p></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {fields[idx] && <Fragment>
                                    <div className="mt-10 lg:max-w-xl ">
                                        <label className="ml-1 block text-md font-medium text-wsd">{t('section-3.id', { ns: 'booking' })}</label>
                                        <div className="mt-3 ml-1">
                                            <div className="flex items-center space-y-0 space-x-10">
                                                <div className="flex items-center">
                                                    <input id={`participants.${idx}.hkidType`} {...register(`participants.${idx}.idType`)} value="HKID" type="radio" className="border-2 focus:outline-none focus:ring-2 focus:ring-yellow-500 h-4 w-4 min-w-[14px] text-wsd border-gray-300" />
                                                    <label for={`participants.${idx}.hkidType`} className="ml-3 block text-md font-medium text-gray-600">{t('section-3.hkid', { ns: 'booking' })}</label>
                                                </div>

                                                <div className="flex items-center">
                                                    <input id={`participants.${idx}.travelDocumentIdType`} {...register(`participants.${idx}.idType`)} value="TRAVEL_DOCUMENT_ID" type="radio" className="border-2 focus:outline-none focus:ring-2 focus:ring-yellow-500 h-4 w-4 min-w-[14px] text-wsd border-gray-300" />
                                                    <label for={`participants.${idx}.travelDocumentIdType`} className="ml-3 block text-md font-medium text-gray-600">{t('section-3.travel-doc', { ns: 'booking' })}</label>
                                                </div>
                                            </div>
                                            <div>
                                                {errors['participants'] && <ErrorMessage error={errors['participants'][idx]?.idType?.message} />}
                                            </div>
                                        </div>
                                    </div>


                                    {getValues(`participants.${idx}.idType`) === 'HKID' && <div className="mt-10">
                                        <label for={`participants.${idx}.hkidAlphabet`} className="ml-1 block text-md font-medium text-wsd">{t('section-3.hkid-no', { ns: 'booking' })}</label>
                                        <label for={`participants.${idx}.hkidNumber`} className="ml-1 block text-sm text-gray-500">{t('section-3.first-4-id', { ns: 'booking' })}</label>
                                        <div className="mt-3 lg:max-w-xl ">
                                            <div className="mt-6 grid gap-y-6 gap-x-4 grid-cols-6">
                                                <div className="col-span-2">
                                                    <div className="mt-1">
                                                        <input id={`participants.${idx}.hkidAlphabet`} {...register(`participants.${idx}.hkidAlphabet`)} type="text" className={`${errors['participants'] && errors['participants'][idx]?.hkidAlphabet ? 'outline-none ring-2 ring-red-500' : 'focus:outline-none focus:ring-2 focus:ring-yellow-500'} text-lg shadow-md block w-full h-14 p-4 rounded-lg`} placeholder={t('section-3.first-digit', { ns: 'booking' })} />
                                                        {errors['participants'] && <ErrorMessage error={errors['participants'][idx]?.hkidAlphabet?.message} />}
                                                    </div>
                                                </div>

                                                <div className="col-span-4">
                                                    <div className="mt-1">
                                                        <input id={`participants.${idx}.hkidNumber`} type="text" {...register(`participants.${idx}.hkidNumber`)} className={`${errors['participants'] && errors['participants'][idx]?.hkidNumber ? 'outline-none ring-2 ring-red-500' : 'focus:outline-none focus:ring-2 focus:ring-yellow-500'} text-lg shadow-md block w-full h-14 p-4 rounded-lg`} placeholder={t('section-3.next-3-digit', { ns: 'booking' })} />
                                                        {errors['participants'] && <ErrorMessage error={errors['participants'][idx]?.hkidNumber?.message} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {getValues(`participants.${idx}.idType`) === 'TRAVEL_DOCUMENT_ID' && <div className="mt-10">
                                        <label for={`participants.${idx}.travelDocumentId`} className="ml-1 block text-md font-medium text-wsd">{t('section-3.travel-doc-no', { ns: 'booking' })}</label>
                                        <label className="ml-1 block text-sm text-gray-500">{t('section-3.first-4-id', { ns: 'booking' })}</label>
                                        <div className="mt-3 lg:max-w-xl ">
                                            <div className="mt-6 grid gap-y-6 gap-x-4 grid-cols-6">
                                                <div className="col-span-6">
                                                    <div className="mt-1">
                                                        <input id={`participants.${idx}.travelDocumentId`} {...register(`participants.${idx}.travelDocumentId`)} type="text" className={`${errors['participants'] && errors['participants'][idx]?.travelDocumentId ? 'outline-none ring-2 ring-red-500' : 'focus:outline-none focus:ring-2 focus:ring-yellow-500'} text-lg shadow-md block w-full h-14 p-4 rounded-lg`} placeholder={t('section-3.first-4-digit', { ns: 'booking' })} />
                                                        {errors['participants'] && <ErrorMessage error={errors['participants'][idx]?.travelDocumentId?.message} />}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>}
                                </Fragment>}

                                <div className="mt-10">
                                    <label for={`participants.${idx}.phoneNumber`} className="ml-1 block text-md font-medium text-wsd">{t('section-3.phone', { ns: 'booking' })} {participants[idx] && participants[idx]?.ageGroup === 'CHILD' ? t('section-3.phone-child-optional', { ns: 'booking' }) : ''}</label>
                                    <div className="mt-3">
                                        <input type="text" id={`participants.${idx}.phoneNumber`} {...register(`participants.${idx}.phoneNumber`)} className={`${errors['participants'] && errors['participants'][idx]?.phoneNumber ? 'outline-none ring-2 ring-red-500' : 'focus:outline-none focus:ring-2 focus:ring-yellow-500'} lg:max-w-xl text-lg shadow-md block w-full h-14 p-4 rounded-lg`} placeholder={t('section-3.enter-phone', { ns: 'booking' })} />
                                        {errors['participants'] && <ErrorMessage error={errors['participants'][idx]?.phoneNumber?.message} />}
                                        {checkPhoneDuplicated(getValues(`participants.${idx}.phoneNumber`)) && <ErrorMessage error={'validations.phoneDuplicated'} />}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                })}

                <div className="flex justify-start lg:justify-center bg-blue-50 bg-opacity-60 py-2 px-4 mt-20">
                    <div className="flex flex-col pt-4 pb-20 mx-6">
                        <div className="flex justify-center py-6">
                            <span className="inline-block text-center text-gray-700 text-md font-medium">
                                <label for="g-recaptcha-response" className="mb-2 block text-xl font-medium text-wsd">{t('section-3.recaptcha', { ns: 'booking' })}</label>
                                <ReCaptcha captchaRef={captchaRef} setRecaptchaVerified={setRecaptchaVerified} displayAlert={displayAlert} />
                                {!recaptchaVerified && isSubmitted && <ErrorMessage error={t('validations.recaptcha.incomplete', { ns: 'errorMessage' })} />}
                            </span>
                        </div>
                        <div className="relative flex items-start mt-6">
                            <div className="flex items-center h-5">
                                <input id="tc1" {...register(`tc1`)} type="checkbox" className="border-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                            </div>
                            <div className="ml-3 text-sm">
                                <label for="tc1" className="text-gray-600"><div dangerouslySetInnerHTML={
                                    { __html: t('section-3.tc1', { ns: 'booking', interpolation: { escapeValue: false } }) }
                                } /></label>
                                {errors['tc1'] && <ErrorMessage error={errors['tc1']?.message} />}
                            </div>
                        </div>
                        <div className="relative flex items-start mt-6">
                            <div className="flex items-center h-5">
                                <input id="tc2" {...register(`tc2`)} type="checkbox" className="border-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                            </div>
                            <div className="ml-3 text-sm">
                                <label for="tc2" className="text-gray-600">{t('section-3.tc2', { ns: 'booking' })}</label>
                                {errors['tc2'] && <ErrorMessage error={errors['tc2']?.message} />}
                            </div>
                        </div>
                        <div className="relative flex items-start mt-6">
                            <div className="flex items-center h-5">
                                <input id="tc3" {...register(`tc3`)} type="checkbox" className="border-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                            </div>
                            <div className="ml-3 text-sm">
                                <label for="tc3" className="text-gray-600">{t('section-3.tc3', { ns: 'booking' })}</label>
                                {errors['tc3'] && <ErrorMessage error={errors['tc3']?.message} />}
                            </div>
                        </div>
                        {/* <div className="relative flex items-start mt-6">
                            <div className="flex items-center h-5">
                                <input id="tc4" {...register(`tc4`)} type="checkbox" className="border-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                            </div>
                            <div className="ml-3 text-sm">
                                <label for="tc4" className="text-gray-600">{t('section-3.tc4', { ns: 'booking' })}</label>
                                {errors['tc4'] && <ErrorMessage error={errors['tc4']?.message} />}
                            </div>
                        </div> */}
                        <div className="relative flex items-start mt-6">
                            <div className="flex items-center h-5">
                                <input id="tc5" {...register(`tc5`)} type="checkbox" className="border-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                            </div>
                            <div className="ml-3 text-sm">
                                <label for="tc5" className="text-gray-600">{t('section-3.tc5', { ns: 'booking' })}</label>
                                {errors['tc5'] && <ErrorMessage error={errors['tc5']?.message} />}
                            </div>
                        </div>
                        <div className="relative flex items-start mt-6">
                            <div className="flex items-center h-5">
                                <input id="tc6" {...register(`tc6`)} type="checkbox" className="border-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                            </div>
                            <div className="ml-3 text-sm">
                                <label for="tc6" className="text-gray-600">{t('section-3.tc6', { ns: 'booking' })}</label>
                                {errors['tc6'] && <ErrorMessage error={errors['tc6']?.message} />}
                            </div>
                        </div>
                        {/* user requested to remove at 09-27
                        <div className="relative flex items-start mt-6">
                            <div className="flex items-center h-5">
                                <input {...register(`hasAgreeEmailPromotion`)} type="checkbox" className="border-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                            </div>
                            <div className="ml-3 text-sm">
                                <label className="text-gray-600">{t('section-3.tc6', { ns: 'booking' })}</label>
                                {errors['hasAgreeEmailPromotion'] && <ErrorMessage error={errors['hasAgreeEmailPromotion']?.message} />}
                            </div>
                        </div> */}

                        <div className="lg:flex lg:justify-center">
                            <div className="lg:flex lg:flex-col">
                                <div className="mt-12 lg:w-[400px]">
                                    <button type="button" onClick={() => resetForm()} className="h-12 w-full p-2 text-xl rounded-full bg-gray-500 text-white font-semibold shadow-md hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-yellow-500">
                                        {t('section-3.back-to-date-select', { ns: 'booking' })}
                                    </button>
                                </div>


                                <div className="mt-6 lg:w-[400px]">
                                    <button type="submit" disabled={!recaptchaVerified} className={`${!recaptchaVerified ? 'bg-gray-500' : 'bg-wsd hover:bg-blue-700 focus:ring-4 focus:ring-yellow-500'} cursor-pointer h-12 w-full lg:max-w-[400px] p-2 text-xl rounded-full text-white font-semibold shadow-md focus:outline-none `}>
                                        {t('section-3.next', { ns: 'booking' })}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>}

            {stage === 'REVIEW' && selectedSession && <Fragment>
                <div className="flex justify-start bg-blue-50 bg-opacity-60 py-2 px-4 mt-4">
                    <div className="flex flex-none items-center px-1">
                        <div className="px-3 py-1 border-1 rounded-full bg-wsd-form text-white text-xl font-bold">
                            4
                        </div>
                        <div className="flex mx-6">
                            <p className="inline-block text-wsd-form font-bold text-xl">{t('section-4.title', { ns: 'booking' })}</p>
                        </div>
                    </div>
                </div>

                <div className="text-lg lg:flex lg:justify-center lg:mt-4">
                    <div className="lg:w-1/2 flex justify-center mx-6 mt-4 lg:pr-16">
                        <div className="flex flex-col text-justify py-4 text-md">
                            <span className="inline-block text-gray-600 text-semibold py-1 pt-3">{t('section-3.reminder', { ns: 'booking' })}</span>
                            <span className="inline-block text-gray-600 text-semibold py-1 pt-3">{t('section-4.reminder', { ns: 'booking' })}</span>
                        </div>
                    </div>
                </div>

                <div className="border-b-2 p-4 pb-8 lg:mt-10 lg:border-b-0">
                    <div className="lg:flex lg:justify-center">
                        <div className="w-full my-6 lg:w-[180px] lg:border-r-2 lg:my-0">
                            <div className="flex justify-start flex-none items-center px-1">
                                <div className="flex mx-4 lg:mx-0">
                                    <span className="inline-block text-left text-wsd font-bold text-xl">{t('section-4.info', { ns: 'booking' })}</span>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-[350px]">
                            <div className="w-full mt-6 lg:mt-0">
                                <div className="flex justify-start flex-none items-center px-1">
                                    <div className="lg:w-[150px] flex mx-4">
                                        <span className="inline-block text-left text-wsd font-bold text-xl">{t('section-4.no-of-participant', { ns: 'booking' })}</span>
                                    </div>
                                    <div className="flex ml-6">
                                        <span className="inline-block text-gray-700 font-medium text-xl">{t('section-4.person', { ns: 'booking', v: totalParticipants, count: totalParticipants })}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full mt-2">
                                <div className="flex justify-start flex-none items-center px-1">
                                    <div className="lg:w-[150px] flex mx-4">
                                        <span className="inline-block text-left text-wsd font-bold text-xl">{t('section-4.date', { ns: 'booking' })}</span>
                                    </div>
                                    <div className="flex ml-6">
                                        <span className="inline-block text-gray-700 font-medium text-xl">{formatInTimeZone(new Date(selectedSession.startAt), 'Asia/Hong_Kong', 'yyyy - MM - dd')}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full mt-2">
                                <div className="flex justify-start flex-none items-center px-1">
                                    <div className="lg:w-[150px] flex mx-4">
                                        <span className="inline-block text-left text-wsd font-bold text-xl">{t('section-4.period', { ns: 'booking' })}</span>
                                    </div>
                                    <div className="flex ml-6">
                                        <span className="inline-block text-gray-700 font-medium text-xl">{`${selectedSession.displayStartAt} - ${selectedSession.displayEndAt}`}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border-b-2 p-4 pb-8 lg:mt-10 lg:border-b-0">
                    <div className="lg:flex lg:justify-center">
                        <div className="w-full my-6 lg:w-[180px] lg:border-r-2 lg:my-0">
                            <div className="flex lg:flex-col justify-start flex-none items-center lg:items-start px-1">
                                <div className="flex mx-4 lg:mx-0">
                                    <span className="inline-block text-left text-wsd font-bold text-xl">{t('section-4.p1', { ns: 'booking' })}</span>
                                </div>
                                <div className="flex ml-6 lg:mx-0">
                                    <span className="inline-block text-gray-700 font-medium text-md">{t('section-4.p1-desc', { ns: 'booking' })}</span>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-[350px]">
                            {getValues('idType') === 'HKID' && <div className="w-full mt-2 lg:mt-0">
                                <div className="flex justify-start flex-none items-center px-1">
                                    <div className="w-[150px] mx-4">
                                        <span className="block text-left text-wsd font-bold text-xl">{t('section-4.hkid-no', { ns: 'booking' })}</span>
                                        <span className="block text-left text-gray-500 font-bold text-sm">{t('section-4.first-4-digit-1', { ns: 'booking' })}</span>
                                        <span className="block text-left text-gray-500 font-bold text-sm">{t('section-4.first-4-digit-2', { ns: 'booking' })}</span>
                                    </div>
                                    <div className="flex ml-6">
                                        <span className="inline-block uppercase text-gray-700 font-medium text-xl">{`${getValues('hkidAlphabet')} ${getValues('hkidNumber')}`}</span>
                                    </div>
                                </div>
                            </div>}

                            {getValues('idType') === 'TRAVEL_DOCUMENT_ID' && <div className="w-full mt-2 lg:mt-0">
                                <div className="flex justify-start flex-none items-center px-1">
                                    <div className="w-[150px] mx-4">
                                        <span className="block text-left text-wsd font-bold text-xl">{t('section-4.travel-doc-no', { ns: 'booking' })}</span>
                                        <span className="block text-left text-gray-500 font-bold text-sm">{t('section-4.first-4-digit-1', { ns: 'booking' })}</span>
                                        <span className="block text-left text-gray-500 font-bold text-sm">{t('section-4.first-4-digit-2', { ns: 'booking' })}</span>
                                    </div>
                                    <div className="flex ml-6">
                                        <span className="inline-block uppercase text-gray-700 font-medium text-xl">{`${getValues('travelDocumentId')}`}</span>
                                    </div>
                                </div>
                            </div>}

                            <div className="w-full mt-2">
                                <div className="flex justify-start flex-none items-center px-1">
                                    <div className="w-[150px] flex mx-4">
                                        <span className="inline-block text-left text-wsd font-bold text-xl">{t('section-4.phone', { ns: 'booking' })}</span>
                                    </div>
                                    <div className="flex ml-6">
                                        <span className="inline-block text-gray-700 font-medium text-xl">{getValues('phoneNumber')}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full mt-2">
                                <div className="flex justify-start flex-none items-center px-1">
                                    <div className="w-[150px] flex mx-4">
                                        <span className="inline-block text-left text-wsd font-bold text-xl">{t('section-4.passphrase', { ns: 'booking' })}</span>
                                    </div>
                                    <div className="flex ml-6">
                                        <span className="inline-block text-gray-700 font-medium text-xl">{getValues('passphrase')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {getValues('participants').map((participant, idx) => {
                    return <div key={idx} className="border-b-2 p-4 pb-8 lg:mt-10 lg:border-b-0">
                        <div className="lg:flex lg:justify-center">
                            <div className="w-full my-6 lg:w-[180px] lg:border-r-2 lg:my-0">
                                <div className="flex justify-start flex-none items-center lg:items-start px-1">
                                    <div className="flex mx-4 lg:mx-0">
                                        <span className="inline-block text-left text-wsd font-bold text-xl">{t('section-4.participant', { ns: 'booking' })} {idx + 2}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="lg:w-[350px]">
                                {participant.idType === 'HKID' && <div className="w-full mt-2 lg:mt-0">
                                    <div className="flex justify-start flex-none items-center lg:items-start px-1">
                                        <div className="w-[150px] mx-4">
                                            <span className="inline-block text-left text-wsd font-bold text-xl">{t('section-4.hkid-no', { ns: 'booking' })}</span>
                                            <span className="inline-block text-left text-gray-500 font-bold text-sm">{t('section-4.first-4-digit-1', { ns: 'booking' })}</span>
                                            <span className="inline-block text-left text-gray-500 font-bold text-sm">{t('section-4.first-4-digit-2', { ns: 'booking' })}</span>
                                        </div>
                                        <div className="flex ml-6">
                                            <span className="inline-block uppercase text-gray-700 font-medium text-xl">{participant.hkidAlphabet} {participant.hkidNumber}</span>
                                        </div>
                                    </div>
                                </div>}

                                {participant.idType === 'TRAVEL_DOCUMENT_ID' && <div className="w-full mt-2 lg:mt-0">
                                    <div className="flex justify-start flex-none items-center lg:items-start px-1">
                                        <div className="w-[150px] mx-4">
                                            <span className="inline-block text-left text-wsd font-bold text-xl">{t('section-4.travel-doc-no', { ns: 'booking' })}</span>
                                            <span className="inline-block text-left text-gray-500 font-bold text-sm">{t('section-4.first-4-digit-1', { ns: 'booking' })}</span>
                                            <span className="inline-block text-left text-gray-500 font-bold text-sm">{t('section-4.first-4-digit-2', { ns: 'booking' })}</span>
                                        </div>
                                        <div className="flex ml-6">
                                            <span className="inline-block uppercase text-gray-700 font-medium text-xl">{participant.travelDocumentId}</span>
                                        </div>
                                    </div>
                                </div>}

                                <div className="w-full mt-2">
                                    <div className="flex justify-start flex-none items-center lg:items-start px-1">
                                        <div className="w-[150px] flex mx-4">
                                            <span className="inline-block text-left text-wsd font-bold text-xl">{t('section-4.phone', { ns: 'booking' })}</span>
                                        </div>
                                        <div className="flex ml-6">
                                            <span className="inline-block text-gray-700 font-medium text-xl">{participant.phoneNumber || ' - '}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                })}

                <div className="lg:flex lg:justify-center mt-12 mx-8">
                    <button type="button" onClick={() => setStageToInfo()} className="h-12 w-full lg:max-w-[400px] p-2 text-xl rounded-full bg-gray-500 text-white font-semibold shadow-md hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-yellow-500">
                        {t('section-4.back', { ns: 'booking' })}
                    </button>
                </div>

                <div className="lg:flex lg:justify-center mt-6 mb-12 mx-8">
                    <button type="button" onClick={() => completeForm()} className={`h-12 w-full lg:max-w-[400px] p-2 text-xl rounded-full bg-wsd text-white font-semibold shadow-md hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-yellow-500`}>
                        {t('section-4.submit', { ns: 'booking' })}
                    </button>
                </div>

            </Fragment>}

            {stage === 'DONE' && <Fragment>
                <div className="flex justify-start bg-blue-50 bg-opacity-60 py-2 px-4 mt-4">
                    <div className="flex flex-none items-center px-1">
                        <div className="px-2 py-1 border-1 rounded-full bg-wsd-form text-white font-semibold text-xl">
                            <FontAwesomeIcon icon={faCheck} alt="Reservation Completed" />
                        </div>
                        <div className="flex mx-6">
                            <p className="inline-block text-wsd-form font-bold text-xl">{t('done.title', { ns: 'booking' })}</p>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center py-2 px-4 mt-3">
                    <div className="flex flex-none items-center px-1">
                        <div className="flex mx-6">
                            <img className="w-40" src="/img/ic-sms.png" alt="" />
                        </div>
                    </div>
                </div>

                <div className="flex justify-center px-12">
                    <span className="lg:w-96 inline-block leading-loose text-center text-gray-600 text-md">{t('done.desc', { ns: 'booking' })}</span>
                </div>

                <div className="flex justify-center px-12 mt-6">
                    <span className="inline-block text-center text-gray-700 text-lg font-medium">{t('done.inquiry-no', { ns: 'booking' })}</span>
                </div>

                <div className="flex justify-center px-12">
                    <span className="inline-block text-center text-gray-700 text-lg font-medium">{groupId}</span>
                </div>

                <div className="my-12 mx-8 flex justify-center">
                    <Link to="/" className="text-center h-12 lg:max-w-[400px] w-full p-2 text-xl rounded-full bg-wsd text-white font-semibold shadow-md hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-yellow-500">
                        {t('done.back', { ns: 'booking' })}
                    </Link>
                </div>
            </Fragment>}

        </form>


        <div className="flex w-[70px] ml-auto sticky bottom-20 lg:bottom-0 p-4 lg:mt-12">
            <AnchorLink aria-label="Back To Top" href='#top' className="items-center rounded-full border border-transparent bg-wsd-form p-3 text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2">
                <ArrowUpIcon title="Back To Top" className="h-4 w-4 lg:h-6 lg:w-6" />
            </AnchorLink>
        </div>


    </div >
}
import ReactGA from 'react-ga4'

export function trackGa(i18n) {
    try {
        if (process.env.REACT_APP_IS_GA_ON) {
            ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, {
                debug: !!process.env.REACT_APP_IS_GA_DEBUG_ON,
            });
            ReactGA.set({
                language: i18n.language === 'en' ? 'en-GB' : i18n.language === 'tc' ? 'zh_HK' : 'zh_CN',
                title: document.title,
                location: window.location.origin + window.location.pathname
            });
            ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        }
    } catch (error) {
        console.error('[GA SEND ERROR]', error)
    }
}


export function trackGaEvt(event, action, value = null) {
    try {
        if (process.env.REACT_APP_IS_GA_ON) {
            ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, {
                debug: !!process.env.REACT_APP_IS_GA_DEBUG_ON,
            });
            ReactGA.event({
                category: event,
                action,
                value: value ? parseInt(value) : null
            });
        }
    } catch (error) {
        console.error('[GA SEND ERROR]', error)
    }
}

export function trackGaErr(exception) {
    try {
        if (process.env.REACT_APP_IS_GA_ON) {
            ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, {
                debug: !!process.env.REACT_APP_IS_GA_DEBUG_ON,
            });
            ReactGA.exception('event', 'exception', {
                'description': exception,
                'fatal': false   // set to true if the error is fatal
            });
        }
    } catch (error) {
        console.error('[GA SEND ERROR]', error)
    }
}



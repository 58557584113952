import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import "react-datepicker/dist/react-datepicker.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { setLocale } from 'yup';
import { yupLocale } from './utils/yupLocale';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Booking from './components/Booking';
import Intro from './components/Intro';
import CheckIn from './components/CheckIn';
import Loading from './components/Loading';
import CancelBooking from './components/CancelBooking';

setLocale(yupLocale);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} >
          <Route index element={
            <Suspense fallback={<Loading />}>
              <Intro title="title.home" />
            </Suspense>
          } />
          {/* {process.env.REACT_APP_IS_ALLOW_NEW_APPLICATION && <Route path="reservation" element={
            <Suspense fallback={<Loading />}>
              <Booking title="title.booking" />
            </Suspense>
          } />}
          {process.env.REACT_APP_IS_ALLOW_NEW_APPLICATION && <Route path="cancel-reservation" element={
            <Suspense fallback={<Loading />}>
              <CancelBooking title="title.cancel" />
            </Suspense>
          } />} */}
          <Route path="etk/:uid" element={
            <Suspense fallback={<Loading />}>
              <CheckIn title="title.ticket" />
            </Suspense>
          } />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next';

export default function Alert({ status = 'SUCCESS', text, setShow, show }) {
    const { t } = useTranslation(['booking']);

    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live="assertive"
                className="container mx-auto z-30 fixed inset-0 flex items-start px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
            >

                <div className="w-full flex justify-end items-center space-y-4">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={show}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0 mt-1">
                                        {status === 'SUCCESS' && <CheckCircleIcon className="h-8 w-8 text-green-400" aria-hidden="true" />}
                                        {status === 'FAILED' && <XCircleIcon className="h-8 w-8 text-red-500" aria-hidden="true" />}
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        {status === 'SUCCESS' && <p className="text-lg font-medium text-gray-900">{t('alert.success', { ns: 'booking' })}</p>}
                                        {status === 'FAILED' && <p className="text-lg font-medium text-gray-900">{t('alert.error', { ns: 'booking' })}</p>}
                                        <p className="mt-1 text-md text-gray-500">{text}</p>
                                    </div>
                                    <div className="ml-4 flex-shrink-0 flex">
                                        <button
                                            type="button"
                                            className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() => {
                                                setShow(false)
                                            }}
                                        >
                                            <XCircleIcon title="Close Dialog" className="mt-1 h-8 w-8" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    )
}
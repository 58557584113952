import { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faFilePdf, faRightLong } from '@fortawesome/free-solid-svg-icons'
import { Disclosure, Transition } from '@headlessui/react'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useOutletContext, useSearchParams } from "react-router-dom";
import { isNaN } from 'lodash';

import AnchorLink from 'react-anchor-link-smooth-scroll'
import { ArrowUpIcon } from '@heroicons/react/outline'

import Alert from './Alert';
import TimeTable from './TimeTable'
import Notification from './Notification'
import Loading from './Loading';
import { trackGa } from '../utils/ga'
import { sanitize } from 'dompurify';
import Detector from './Detector';

const applicationPoints = {
    tc: [
        {
            'title': '參觀人士須知',
            'content': <div>
                <div className="mb-6 break-all"><p>
                    (I)  注意事項:
                </p></div>
                <ol className="ml-5 break-all list-decimal list-outside">
                    <li className="p-2">從巴域街出發至前深水埗配水庫需步行約15分鐘，路程主要為狹窄山路及梯級，需要一定的體力，參觀人士應衡量個人實際身體狀況，並考慮是否需要由照料者陪同和協助參觀；參觀人士於整個行程中有責任對其自身安全負責。如有特別需要人士有興趣參觀前深水埗配水庫，請透過查詢熱線 （6679 6445）或電郵（<a className="cursor-pointer text-blue-600" href="mailto:exsspsr@wsd.gov.hk">exsspsr@wsd.gov.hk</a>）與水務署聯絡。</li>
                    <li className="p-2">參觀人數上限為100人。</li>
                    <li className="p-2">為維持秩序，本署會視乎需要而採取適當的人流控制措施。如當日參觀人數超出上限，本署將因應場地可容納人數，按先到先得方法安排參觀人士入場。參觀人士須按本署職員的指示於入口處等候安排進場。</li>
                    <li className="p-2">參觀人士需確保參觀當日自身健康狀況良好；如有任何身體不適或出現明顯的病徵（例如發燒、咳嗽、氣促、嘔吐和腹瀉等），均不宜前往參觀。</li>
                    <li className="p-2">如本署職員於任何時候觀察到參觀人士的健康狀況出現任何傳染病徵狀，本署職員有權終止其參觀活動，以策安全。</li>
                    <li className="p-2">場內不設公眾洗手間。如有需要，參觀人士應預先前往就近相關設施。</li>
                    <li className="p-2">場內不設寄存設施，參觀人士需隨身攜帶所有個人物品進行參觀。參觀人士不可攜帶任何大型提包及行李進入場地。如本署職員認為參觀人士所攜帶物品對其他使用者造成煩擾或不便，本署職員有權拒絕違規者進場或要求其立即離開場地。</li>
                    <li className="p-2">場地為保安電視監察及攝錄地帶。</li>
                    <li className="p-2">如遇3號或以上熱帶氣旋警告信號; 或黃色或以上暴雨警告信號，場地將不會開放。待警告信號解除後，場地將會盡快重新開放。若警告信號取消時距離關閉時間不足1小時，當日將不會開放。若警告信號在場地開放後始生效，場內參觀將會暫停。場內會維持有限度運作，直至參觀人士可以安全離開為止。有關惡劣天氣的最新安排會適時透過本活動網頁公布，敬請留意。</li>
                    <li className="p-2">本署會視乎天氣或場地實際情況決定開放或關閉場地。</li>
                    <li className="p-2">如有疑問，請致電查詢熱線6679 6445或電郵至 <a className="cursor-pointer text-blue-600" href="mailto:exsspsr@wsd.gov.hk">exsspsr@wsd.gov.hk</a> 與水務署聯絡。查詢熱線的服務時間為每日上午9時至下午5時。</li>
                </ol>
                <div className="mt-10 mb-6 break-all"><p>
                    (II) 參觀人士個人裝備建議：
                </p></div>
                <ol className="ml-5 break-all list-decimal list-outside">
                    <li className="p-2">穿著合適衣服及運動鞋</li>
                    <li className="p-2">防疫用品（包括搓手液和消毒濕紙巾等）</li>
                    <li className="p-2">防蚊用品（例如：蚊怕水）</li>
                    <li className="p-2">配備上網及掃描二維碼功能的個人通訊設備（以收聽場地語音導航）</li>
                    <li className="p-2">耳筒（以接駁參觀人士的個人通訊設備收聽語音導航）</li>
                    <li className="p-2">雨具</li>
                    <li className="p-2">飲用水</li>
                </ol>
            </div>
        },
        {
            'title': '場地守則',
            'content': <div>
                <div className="mb-6 break-all"><p>
                本署致力提供一個安全及舒適的參觀場地予所有參觀人士。參觀人士需要負責自己的個人行為，並請遵守以下場地守則：
                </p></div><ol className="ml-5 break-all list-decimal list-outside">
                <li className="p-2">未滿12歲的參觀人士必須由成人陪同方可進入，請小心看顧隨行兒童。</li>
                <li className="p-2">除導盲犬、助聽犬及服務犬外，請勿攜帶任何寵物進入前深水埗配水庫。</li>
                <li className="p-2">前深水埗配水庫目前只開放作參觀之用，本署不接受參觀人士於參觀期間作其他牟利或非牟利用途，例如街頭表演、戶外節目、商業攝影和錄像等。本署職員有權拒絕違規者進場或要求立即離開場地。</li>
                <li className="p-2">為避免影響其他參觀人士，場地内禁止使用閃光或照明器具、無人機航拍、自拍杆和腳架拍照或錄影，以及進行婚紗照、畢業照或其他造型照等拍攝或錄影活動。</li>
                <li className="p-2">參觀活動期間請聽從本署職員指示。參觀人士請勿在場內奔跑、嬉戲、喧嘩、攀爬場內石塊／構建物，或進入任何不向公眾開放的範圍（例如圍欄圍封範圍），免生危險。如有損傷，本署恕不負責。</li>
                <li className="p-2">參觀人士於參觀期間必須保持良好個人衞生及適當社交距離，並將個人垃圾帶走。</li>
                <li className="p-2">參觀人士不得在場內進食（喝清水及服用藥品除外）、飲酒、吸煙或攜帶有害或易燃物品。</li>
                <li className="p-2">參觀人士須於參觀時間完結前離開場地，請勿逗留。</li>
                <li className="p-2">參觀人士不得在場內拋擲物件、粗言穢語或行為不檢。</li>
                <li className="p-2">參觀人士不得在場內踏單車、滑板、進行滾軸溜冰、或任何球類活動。</li>
                <li className="p-2">參觀人士不得在場內搭建帳篷露營及野餐。</li>
                <li className="p-2">參觀人士不得在場內放風箏或操作模型飛機。</li>
                <li className="p-2">參觀人士不得在場使用揚聲器。</li>
                <li className="p-2">參觀人士不得在場內展示橫額、彩旗、宣傳單張或任何形式之廣告。</li>
                <li className="p-2">參觀人士不得在場內進行未經授權的活動、示威、演講辯論、公眾集會或其他公開表演。</li>
                <li className="p-2">嚴禁行乞、收取施捨、賭博、兜售或進行任何非法或不道德的活動。</li>
                <li className="p-2">參觀人士不得在場內派發或售賣推廣資料、紀念品及贈品。</li>
                <li className="p-2">請自行保管私人物品，如有任何損毀、損傷或損失，本署均毋須負起任何索償、費用、毀壞及開支的責任。</li>
                <li className="p-2">參觀人士不得擅自取走、擾亂或以其他方式干預場內任何設施或物品。</li>
                <li className="p-2">參觀期間請勿做出任何行為（例如在牆上塗鴉，腳踢石柱等等）會潛在損害沿途環境或建築物的任何一部份及周邊範圍。如有損壞，本署有權作出追究及要求作出賠償。</li>
                <li className="p-2">如本署職員認為參觀人士相當可能導致場內任何一部份設施或物品損毀，本署職員有權採取合理措施阻止。</li>
                <li className="p-2">參觀人士不得故意妨礙任何正在執行職責的本署職員，或故意妨礙、騷擾、干擾或煩擾任何其他場內的參觀人士。</li>
                <li className="p-2">本署職員有權拒絕違規者進場或要求其立即離開場地。</li>
                <li className="p-2">本署保留權利及絕對酌情權隨時改變、更改、增補、刪除、修訂及更新此場地守則而無須給予任何理由及預先通知。</li>
            </ol></div>
        },
        // {
        //     'title': '申請方法',
        //     'content': <div><ol className="ml-5 break-all list-decimal list-outside">
        //         <li className="p-2">請於參觀時段前透過網上預約系統申請。預約系統會顯示自助導賞遊約2個月內可以接受預約的參觀時段，並會於每月的第一個工作日上午九時更新。</li>
        //         <li className="p-2">本署只接受參加者<strong>30天</strong>內以自助導賞遊形式參觀前深水埗配水庫一次。為使更多人士有機會參與此參觀活動，申請者及其成員成功參與自助導賞遊後不得於<strong>30天</strong>內重複報名參觀活動。所有申請記錄最遲會於每月的第一個工作日上午六時三十分前更新。</li>
        //         <li className="p-2">如個別人士需要本署協助辦理網上預約，或對報名、更改資料、場地或活動資訊有疑問，請致電查詢熱線6679 6445或電郵（self_guided_exsspsr@wsd.gov.hk）與水務署聯絡。查詢熱線的服務時間為每日上午九時至下午五時。</li>
        //     </ol></div>
        // },
        // {

        //     'title': '申請指引及參觀規則',
        //     'content': <div><ol className="ml-5 break-all list-decimal list-outside">
        //         <li className="p-2">參加者必須於參觀當日年滿8歲；未滿16歲的參加者必須由成人陪同參加。另外，參加者不可攜同嬰兒進入前深水埗配水庫。</li>
        //         <li className="p-2">每節參觀時段的參觀人數上限為70人。</li>
        //         <li className="p-2">申請者必須年滿16歲，每次申請參加人數（包括申請者）最多為4人，申請確認短訊及電子入場門券會一併發送至申請者的手提電話號碼。</li>
        //         <li className="p-2">參加者若提供不完整或失實資料，將會被取消資格。</li>
        //         <li className="p-2">如有特別需要人士有興趣參與自助導賞遊，請透過查詢熱線 （6679 6445）或電郵（self_guided_exsspsr@wsd.gov.hk）與水務署聯絡。從巴域街出發至前深水埗配水庫需步行約15分鐘，路程主要為狹窄山路及梯級，需要一定的體力，申請者於申請時應衡量個人及其成員實際身體狀況，考慮是否適合參加該自助導賞遊及需要由照料者陪同和協助參加自助導賞遊；參加者於整個行程中有責任對其自身安全負責。本署職員有權因應當時實際情況及需要拒絕任何人士參與活動。</li>
        //         <li className='p-2'>申請者或參加者如欲取消已預約之參觀或更改參加人數，必須最遲於參觀時段前透過網上預約系統取消預約。如申請者或參加者未依預約之參觀時段出席活動，本署有權拒絕相關人士日後參觀水務設施的申請或延長受影響的申請期限。如申請者或參加者因身體不適或突發事件而未能依預約之參觀時段出席活動，需提供合理缺席原因的相關證明（例如：醫生紙等）供本署確認以更新有關的申請紀錄。</li>
        //         <li className="p-2">所有參觀預約不可轉讓。一經發現，本署將取消相關人士的參觀資格及不作另行通知。</li>
        //         <li className="p-2">為使更多人士有機會參與此參觀活動，申請者及其成員成功參與自助導賞遊後不得重複報名30天內的參觀活動。一經發現，本署將會取消相關人士參觀的資格及不作另行通知。</li>
        //         <li className="p-2">參加者須自行安排交通工具前往參觀地點。</li>
        //         <li className="p-2">參加者須依所預約日期及時間蒞臨前深水埗配水庫參觀，並於入口處向本署職員出示電子入場門券。場內登記處會於該參觀時段開始前15分鐘開放，參加者須按本署職員要求展示申請確認短訊、電子入場門券及身份證明文件以作核實。如有需要，本署職員亦會致電確認參加者身分。</li>
        //         <li className="p-2">參加者須於所選擇的參觀時段開始後30分鐘內進場，並於所選參觀時段完結前離開場地，請勿逗留。本署不會為過時入場申請者作出補團或特別安排。</li>
        //         <li className="p-2">若3號或以上熱帶氣旋警告信號; 或黃色或以上暴雨警告信號於參觀活動開始前3小時內仍然生效，當天的參觀活動將會自動取消。有關活動取消資訊會適時透過本活動網頁發布。在一般情況下，本署將於下一個工作天早上更新有關的申請紀錄，以便受影響的參加者可盡快再透過網上預約系統申請參觀活動。</li>
        //         <li className="p-2">本署會視乎天氣或場地實際情況決定繼續或暫停參觀活動。</li>
        //         <li className="p-2">本署有權取消任何預先安排的參觀活動，有關活動取消資訊會適時透過本活動網頁發布。本署不會為受活動取消影響的申請者作出補團或特別安排。</li>
        //         <li className="p-2">前深水埗配水庫目前只開放作導賞團及自助導賞遊之用，本署不接受參加者藉參與自助導賞遊作其他牟利或非牟利用途，例如街頭表演、戶外節目、商業攝影和錄像等。本署職員有權拒絕違規者進場或要求立即離開場地。</li>
        //         <li className="p-2">為了不影響參觀活動，參加者於參觀期間禁止使用無人機航拍和腳架拍照或錄影。</li>
        //         <li className="p-2">除導盲犬外，請勿攜帶任何寵物進入前深水埗配水庫。</li>
        //         <li className="p-2">場內不設寄存設施，參加者需隨身攜帶所有個人物品進行參觀。參加者不可攜帶任何大型提包及行李進入場地。如本署職員認為參加者所攜帶物品對其他使用者造成煩擾或不便，本署職員有權拒絕違規者進場或要求其立即離開場地。</li>
        //         <li className="p-2">本署將於參觀活動期間進行拍攝及錄影，有關之拍攝相片及錄影片段或會作日後推廣及宣傳之用。參加者如不同意上述安排請告知本署職員並簽署「不授權活動照片及錄影片段作宣傳用途」表格。</li>
        //         <li className="p-2">凡由參觀活動或任何與此有關或連帶的目的所引起或引致對參加者或其財產造成的任何損毀、損傷或損失，本署均毋須負起任何索償、費用、毀壞及開支的責任。</li>
        //         <li className="p-2">如對本活動有其他建議，請電郵至<a className="cursor-pointer text-blue-600" href="mailto:self_guided_exsspsr@wsd.gov.hk">self_guided_exsspsr@wsd.gov.hk</a>。</li>
        //     </ol></div>
        // },
        // {
        //     'title': '參加者須知及場地守則',
        //     'content': <div>
        //         <span>本署致力提供一個安全及舒適的參觀場地予所有參觀者。參加者需要負責自己的個人行為，並請留意以下參加者須知及遵守場地守則：</span>
        //         <ol className="break-all list-none list-inside">
        //             <li className="p-4">(I) 參觀前注意事項：
        //                 <ul className="ml-5 pl-3 mt-2 list-decimal list-outside">
        //                     <li className="p-1">場地為保安電視監察及攝錄地帶。</li>
        //                     <li className="p-1">參加者需確保參觀活動當日自身健康狀況良好；如有任何身體不適或出現明顯的病徵（例如發燒、咳嗽、氣促、嘔吐和腹瀉等），均不宜參與活動。</li>
        //                     <li className="p-1">如本署職員於任何時候觀察到參加者的健康狀況不宜參與當日活動或出現任何傳染病徵狀，本署職員有權終止其參與活動，以策安全。本署並不會為該參加者作出補團或特別安排。</li>
        //                     <li className="p-1">如參加者的體温高於攝氏37.5度，本署職員會終止其參與參觀活動。</li>
        //                     <li className="p-1">場內不設公眾洗手間。如有需要，參加者應預先前往就近相關設施。</li>
        //                     <li className="p-1">場內不設寄存設施，參加者需隨身攜帶所有個人物品進行參觀。參加者不可攜帶任何大型提包及行李進入場地。如本署職員認為參加者所攜帶物品對其他使用者造成煩擾或不便，本署職員有權拒絕違規者進場或要求立即離開場地。</li>

        //                 </ul>
        //             </li>
        //             <li className="p-4">(II) 參加者個人裝備建議：
        //                 <ul className="ml-5 pl-3 mt-2 list-decimal list-outside">
        //                     <li className="p-1">穿著合適衣服及運動鞋</li>
        //                     <li className="p-1">個人清潔用品（例如搓手液和消毒濕紙巾等）</li>
        //                     <li className="p-1">防蚊用品（例如：蚊怕水）</li>
        //                     <li className="p-1">配備上網及掃描二維碼功能的個人通訊設備（以收聽自助導賞系統的語音導航）</li>
        //                     <li className="p-1">耳筒（以接駁參加者的個人通訊設備收聽語音導航）</li>
        //                     <li className="p-1">雨具</li>
        //                     <li className="p-1">飲用水</li>
        //                 </ul>
        //             </li>
        //             <li className="p-4">(III) 參觀期間注意事項：
        //                 <ul className="ml-5 pl-3 mt-2 list-decimal list-outside">
        //                     <li className="p-1">參觀活動期間請聽從本署職員指示。參加者請勿在場內奔跑、攀爬場內石塊／構建物，或進入任何不向公眾開放的範圍（例如圍欄圍封範圍），免生危險。如有損傷，本署恕不負責。</li>
        //                     <li className="p-1">參加者於活動舉行期間須注意安全及小心保管個人財物，如有遺失及損壞本署恕不負責。</li>
        //                     <li className="p-1">參加者不得在參觀期間飲食（喝清水及服用藥品除外）。</li>
        //                     <li className="p-1">參加者於參觀期間必須保持良好個人衞生及適當社交距離，並將個人垃圾帶走。</li>
        //                     <li className="p-1">參加者不得在場內進食、飲酒、吸煙、奔跑、喧嘩或攜帶有害或易燃物品。</li>
        //                     <li className="p-1">參加者須於參觀時段完結前離開會場，請勿逗留。</li>
        //                     <li className="p-1">參加者不得擅自取走、擾亂或以其他方式干預場內任何設施或物品。</li>
        //                     <li className="p-1">參加者不得在場內拋擲物件、粗言穢語或行為不檢。</li>
        //                     <li className="p-1">參加者不得在場內踏單車、滑板、進行滾軸溜冰、或任何球類活動。</li>
        //                     <li className="p-1">參加者不得在場使用揚聲器。</li>
        //                     <li className="p-1">參加者不得在場內展示橫額、彩旗、宣傳單張或任何形式之廣告。</li>
        //                     <li className="p-1">參加者不得在場內進行未經授權的活動、示威、演講或其他公開表演。</li>
        //                     <li className="p-1">參加者不得在場內派發或售賣推廣資料、紀念品及贈品。</li>
        //                     <li className="p-1">活動期間請勿做出任何行為（例如在牆上塗鴉，腳踢石柱等等）會潛在損害沿途環境或建築物的任何一部份及周邊範圍。如有損壞，本署有權作出追究及要求作出賠償。</li>
        //                     <li className="p-1">如本署職員認為參加者相當可能導致場內任何一部份設施或物品損毀，本署職員有權採取合理措施阻止。</li>
        //                     <li className="p-1">參加者不得故意妨礙任何正在執行職責的本署職員，或故意妨礙、騷擾、干擾或煩擾任何其他場內的參加者。</li>
        //                     <li className="p-1">本署職員有權拒絕違規者進場或要求其立即離開場地。</li>
        //                     <li className="p-1">本署保留權利及絕對酌情權隨時改變、更改、增補、刪除、修訂及更新此場地守則而無須給予任何理由及預先通知。</li>
        //                 </ul>
        //             </li>
        //         </ol></div>
        // },
        {
            'title': '個人資料收集聲明',
            'content': <div className="break-all">
                <p>香港特別行政區政府水務署（下稱「本署」）會確保所有透過水務署的「前深水埗配水庫」互聯網網站（下稱「本網站」）遞交的個人資料，均按照<a className="cursor-pointer text-blue-600" href="https://www.elegislation.gov.hk/hk/cap486!zh-Hant-HK" target="_blank" title="香港法例網頁，此超連結會在新視窗開啟">《個人資料（私隱）條例》</a>的有關條文處理。</p>
                <p className='text-3xl font-bold py-6 text-wsd'>個人資料的收集</p>
                <p>本署會記錄使用者瀏覽本網站的次數，但並不會收集任何足以辨識使用者身份的資料。所收集的瀏覽次數紀錄只會用於製作統計報告及調查電腦系統問題，以助本署改善本網站的服務。</p>
                <p>每當你使用我們的網上服務，本署都會收集相關的個人資料。此等資料可能包括姓名、身份證號碼、電話號碼、郵寄地址、電郵地址或水費單上的用戶編號。你提供資料完全出於自願。如資料不全，本署可能無法處理你的申請。請確保你所提供的資料正確。本署邀請你提供資料時，會列明收集資料的目的和用途，並會告知你如何要求查閱或改正所提供的個人資料。</p>
                <p className='text-3xl font-bold py-6 text-wsd'>個人資料的使用</p>
                <p>本署會使用你所提供的資料作下列一般用途：提供你所要求的服務、改善我們的服務、聯絡閣下、進行研究調查、及提供不記名的報告予內部使用等。</p>
                <p>你所提供的資料或會向政府其他決策局、部門以及其他相關機構公開，作上述用途。除非法律許可或有所規定，政府不會在未得到你的同意下透露你的個人資料予其他非相關的第三者。</p>
                <p className='text-3xl font-bold py-6 text-wsd'>查閱個人資料</p>
                <p>根據《個人資料（私隱）條例》，任何人均有權要求查閱或改正已向本署提供的個人資料。有關要求須透過<a className="cursor-pointer text-blue-600" href="https://www.pcpd.org.hk/tc_chi/resources_centre/publications/forms/files/Dformc.pdf" target="_blank" title="查閱資料要求表格OPS003，此超連結會在新視窗開啟">查閱資料要求表格OPS003</a>提出，填妥表格後，請以<a className="cursor-pointer text-blue-600" href="https://www.wsd.gov.hk/tc/contact-us/in-writing/index.html" target="_blank">郵寄</a>方式寄交水務署公共關係組收。根據《個人資料（私隱）條例》第20(3)(e)條，如不使用上述表格提出申請，有關方面或會拒絕查閱資料的要求。上述表格可在<a className="cursor-pointer text-blue-600" href="https://www.wsd.gov.hk/tc/contact-us/offices/index.html" target="_blank" title="水務署網頁，此超連結會在新視窗開啟">水務署總部</a>及各區民政事務處索取，你也可在<a className="cursor-pointer text-blue-600" href="https://www.pcpd.org.hk/" target="_blank" title="個人資料私隱專員公署網頁，此超連結會在新視窗開啟">個人資料私隱專員公署網頁</a>下載。</p>
                <p className='text-3xl font-bold py-6 text-wsd'>保安</p>
                <p>本網站使用SSL通訊協定傳送網上登記的資料，以確保你的個人資料得到保護。本署會將你於本網站提供的個人資料保密，只有獲得授權的人士才可查閱。</p>
            </div>
        }
    ],
    en: [
        // {
        //     'title': 'Application Method',
        //     'content': <div><ol className="ml-5 list-decimal list-outside">
        //         <li className="p-2">Please submit the application through online booking system prior to the visiting session starts.  The booking system will show and accept booking for visiting sessions of the self-guided tours in about 2 months and will be updated at 9:00am on the first working day of each month.</li>
        //         <li className="p-2">WSD only accept participants visiting the Ex-SSPSR in the form of self-guided tours once every <strong>30 days</strong>.  Participants are not allowed to make another booking within 30 days after successfully joining the self-guided tour.  All records will be updated at 06:30am on the first working day of each month before the online booking system is reopen for booking.</li>
        //         <li className="p-2">For individuals requiring assistance from WSD on the online booking or for enquiries on application, information amendment, venue or programme details, please contact our enquiry hotline 6679 6445 or email (self_guided_exsspsr@wsd.gov.hk).  The operation hour of the enquiry hotline shall be 9:00 a.m. to 5:00 p.m. every day.</li>
        //     </ol></div>
        // },
        // {

        //     'title': 'Application Guidance Notes and Rules for Visit',
        //     'content': <div><ol className="ml-5 list-decimal list-outside">
        //         <li className="p-2">Participants must be at least 8 years old at the time of visit.  Participants under 16 will only be allowed to visit when accompanied by an adult.  In addition, participants are not allowed to enter the Ex-SSPSR with infants.</li>
        //         <li className="p-2">Number of participants in each visiting session is 70 persons.</li>
        //         <li className="p-2">Applicants must be aged 16 or above.  The maximum number of participants for each application is 4 (including the applicant).  Confirmation SMS of the application and admission e-ticket will be sent to applicant’s mobile number.</li>
        //         <li className="p-2">The right of visit of participants will be cancelled if they provide incomplete or inaccurate information.</li>
        //         <li className="p-2">For persons with special needs interested in joining the self-guided tour, please contact WSD through our enquiry hotline (6679 6445) or email (self_guided_exsspsr@wsd.gov.hk).  The route between Berwick Street and the Ex-SSPSR takes a walk of about 15 minutes.  It consists of mainly narrow trails and stairs and certain efforts will be required.  Applicants should assess their own as well as their participants’ health conditions and consider whether it is necessary to be accompanied and assisted by care takers before making application for the self-guided tour.  Participants are responsible for their own safety during the tours.  WSD staff has the right to refuse any person from joining the tour based on actual situation and needs.</li>
        //         <li className='p-2'>If the applicant or participant wants to cancel the visit reservation or change the number of participants, the cancellation request should be made through the online booking system before the visiting session starts.  If the applicant or participant does not show up at the reserved visiting session, WSD reserves the right to refuse applications from the relevant individuals for visiting any waterworks facilities in the future with an additional time bar.  If applicant or participant is not able to join the reserved visiting session due to sickness or unforeseen circumstances, relevant proof (e.g. medical certificate, etc.) to justify the absence with reasonable reasons is required for WSD’s checking such that the relevant application record could be updated.</li>
        //         <li className="p-2">All the visit reservations are not transferrable.  Once it is found, the right of visits of relevant individuals will be cancelled without further notice.</li>
        //         <li className="p-2">To enable more people to participate in the visiting programme, applicants and participants are not allowed to make duplicate booking within 30 days after successfully joining the self-guided tour.  Once it is found, WSD will cancel the visits of relevant individuals without further notice.</li>
        //         <li className="p-2">Participants shall arrange their own transport to reach / leave the visiting venue.</li>
        //         <li className="p-2">Participants are required to arrive the Ex-SSPSR on time as the visiting date and time applied, and present the admission e-ticket to WSD staff at the entrance.  Registration counter will be opened 15 minutes before the visiting session starts, and participants are also requested to present confirmation SMS of application, admission e-ticket and relevant identification documents to WSD staff to verify the authenticity of information during registration.  WSD staff will also verify the contact numbers by making phone calls if needed.</li>
        //         <li className="p-2">Participants should admit into the venue within 30 minutes after the applied visiting session started and leave the venue after the visiting session ends.  Do not linger in the venue after that. WSD will not arrange any make-up tours or make special arrangement to those participants who are late for admission.</li>
        //         <li className="p-2">The visit will be cancelled automatically if Tropical Cyclone Warning Signal No.3 or above; or Amber Rainstorm Warning Signal or above is in force within 3 hours before the visit is scheduled to begin.  Under normal circumstances, WSD will update the record in the morning of next working day, in order to facilitate the affected participants to submit the application for visit through online booking system again at the earliest time.</li>
        //         <li className="p-2">WSD will decide to continue or suspend the visit depending on the weather or the actual situation of the venue.</li>
        //         <li className="p-2">WSD reserves the right to cancel any scheduled visit, the related cancellation notice will be announced in the programme website at appropriate time.  WSD will not arrange any make-up tours or make special arrangement to affected participants.</li>
        //         <li className="p-2">WSD currently only allows restricted opening of the Ex-SSPSR for visiting by the public in the form of guided and self-guided tours.  Participants are not allowed to take on the self-guided tours for other profit-making or non-profit-making purposes, such as street performances, outdoor shows, commercial photography and videography, etc.  WSD staff has the right to refuse entry of any persons or ask them to leave the site immediately if they refuse to abide the rules.</li>
        //         <li className="p-2">Participants are prohibited from using drones and tripods to take photos/videos during the visit in order to avoid any disturbance to the visit.</li>
        //         <li className="p-2">Except guide dogs, pets are not allowed to enter the Ex-SSPSR.</li>
        //         <li className="p-2">No storage facility will be provided in the venue.  Participants should take all their personal belongings with them during the visit.  Please do not bring any sizable luggage to the venue.  Participant carrying any article which in the opinion of WSD staff may cause annoyance or inconvenience to other participants, WSD staff has the right to refuse entry of any persons or ask them to leave the site immediately if they refuse to abide the rules.</li>
        //         <li className="p-2">WSD will take photos and videos during the visit and may use the photos and videos for purpose of promotion and publicity.  Should participants do not agree with such arrangement, please inform WSD staff on the date of visit and sign on the form of "Notice of Objection for Use of Photos and Videos Taken during the Event for the Purpose of Promotion and Publicity".</li>
        //         <li className="p-2">WSD is exempted from all the liabilities of claims, costs, damages and expenses in respect of any damage, injury or loss to the participants or their properties occasioned by or in any way arising from the visit or any purpose pertaining to or associated with the visit.</li>
        //         <li className="p-2">For any other suggestions on this programme, please email to <a className="cursor-pointer text-blue-600" href="mailto:self_guided_exsspsr@wsd.gov.hk">self_guided_exsspsr@wsd.gov.hk</a>.</li>
        //     </ol></div>
        // },
        // {
        //     'title': 'Guidance Notes for Participants and House Rules',
        //     'content': <div>
        //         <span>WSD endeavours to offer a safe environment and enjoyable experience for all of our visitors.  Participants are responsible for their behavior at all times.  Please note the following:</span>
        //         <ol className="list-none list-inside">
        //             <li className="p-4">(I) Points to note before the visit:
        //                 <ul className="ml-5 pl-3 mt-2 list-decimal list-outside">
        //                     <li className="p-1">Venue is under security camera surveillance and recording.</li>
        //                     <li className="p-1">Participants should ensure that their health conditions are good on the date of visit. If there is any discomfort or develop obvious symptoms (e.g. fever, cough, shortness of breath, vomiting and diarrhoea), it is not recommended to participate in the visit.</li>
        //                     <li className="p-1">For the sake of safety, WSD staff may reject anyone whose health conditions are considered unsuitable for participation and those who have symptoms of infectious diseases from participating in the visit. WSD will not arrange any make-up tours or make special arrangement to the concerned participants.</li>
        //                     <li className="p-1">WSD staff will measure the body temperature of the participants at the entrance.  If the body temperature of a participant is higher than 37.5°C, WSD staff will terminate his/her participation in the visit.</li>
        //                     <li className="p-1">Toilet will not be provided for public in the venue.  Participants should use the toilets nearby before attend the visit.</li>
        //                     <li className="p-1">Storage facility will not be provided in the venue.  Participants should take all their personal belongings with them during the visit.  Please do not bring any sizable luggage to the venue.  Participant carrying any article which in the opinion of WSD staff may cause annoyance or inconvenience to other participants, WSD staff has the right to refuse entry of any persons or ask them to leave the site immediately if they refuse to abide the rules.</li>

        //                 </ul>
        //             </li>
        //             <li className="p-4">(II) Recommended participants’ equipment:
        //                 <ul className="ml-5 pl-3 mt-2 list-decimal list-outside">
        //                     <li className="p-1">Please wear suitable clothes and sport shoes</li>
        //                     <li className="p-1">Personal cleansing supplies (such as hand sanitizer and disinfectant wet tissues）</li>
        //                     <li className="p-1">Anti-mosquito products (such as mosquito repellent）</li>
        //                     <li className="p-1">Personal mobile devices with the function to access internet and scan QR code (for listening to the audio guide of self-guided tour）</li>
        //                     <li className="p-1">Earphones (to connect participants’ personal mobile devices in order to listen the audio guide）</li>
        //                     <li className="p-1">Rain gear</li>
        //                     <li className="p-1">Drinking water</li>
        //                 </ul>
        //             </li>
        //             <li className="p-4">(III) Points to note during the visit:
        //                 <ul className="ml-5 pl-3 mt-2 list-decimal list-outside">
        //                     <li className="p-1">Please follow WSD staff instructions during the visit.  Participants should not run, climb up any blocks/ structures or do not get into forbidden areas (e.g. areas fenced off by railings) to stay safe.  WSD will not be responsible for any injuries.</li>
        //                     <li className="p-1">Participants should pay attention to their own safety and take care of their personal belongings during the visit. WSD will not be responsible for any loss or damage.</li>
        //                     <li className="p-1">Participants are not allowed to drink or eat throughout the visit (except drinking water and taking medicine).</li>
        //                     <li className="p-1">Participants are reminded to maintain good personal hygiene and appropriate social distancing throughout the visit.  Litter should be taken away by participants.</li>
        //                     <li className="p-1">Eating, alcoholic beverages, smoking, running, yelling, hazardous or inflammable item are not allowed.</li>
        //                     <li className="p-1">Participants should leave the venue after the applied visiting session ends.  Do not linger in the venue after that.</li>
        //                     <li className="p-1">Participants should not remove, disturb or otherwise interfere with any facility or item.</li>
        //                     <li className="p-1">Throwing objects, employing foul language and behaving in a disorderly manner in the venue are not allowed.</li>
        //                     <li className="p-1">Cycling, skateboarding, rollerblading or ball games are not allowed.</li>
        //                     <li className="p-1">Loudhailers should not be used in the venue.</li>
        //                     <li className="p-1">Participants should not display banners, buntings, flyers or publicity materials of any kind in the venue.</li>
        //                     <li className="p-1">Unauthorised events, demonstrations, speeches and other public performance are not allowed.</li>
        //                     <li className="p-1">Promotional materials, souvenirs and give-away items should not be distributed or sold in the venue.</li>
        //                     <li className="p-1">Please do not perform any actions that could potentially harm any surroundings or any part of structures throughout the visit (e.g. drawing on the wall, kicking the stone pillar, etc).  WSD reserves the right to claim compensation for any loss or damage.</li>
        //                     <li className="p-1">WSD staff may take reasonable steps to stop participants from doing any act which, in his opinion, is likely to cause damage to any facility or item in the venue.</li>
        //                     <li className="p-1">Participants should not wilfully obstruct any WSD staff in performing his duty or wilfully obstruct, disturb, interrupt or annoy any other participants in the venue.</li>
        //                     <li className="p-1">WSD staff has the right to refuse entry of any persons or ask them to leave the site immediately if they refuse to abide the rules.</li>
        //                     <li className="p-1">WSD reserves the right and is entitled to change, alter, add, delete, revise and update all information on this “House Rules” at any time at its absolute discretion without giving any reasons and prior notice.</li>
        //                 </ul>
        //             </li>
        //         </ol></div>
        // },
        {
            'title': 'Guidance Notes for Visitors',
            'content': <div>
                <div className="mb-6"><p>
                    (I) Points to note:
                </p></div>
                <ol className="ml-5 list-decimal list-outside">
                    <li className="p-2">The route between Berwick Street and the Ex-SSPSR takes a walk of about 15 minutes. It consists of mainly narrow trails and stairs and certain efforts will be required. Visitors should assess their own health conditions and consider whether it is necessary to be accompanied and assisted by care takers before visit. Visitors are responsible for their own safety during the tours. If persons with special needs are interested in visiting the Ex-SSPSR, please contact the Water Supplies Department via the inquiry hotline (6679 6445) or email (<a className="cursor-pointer text-blue-600" href="mailto:exsspsr@wsd.gov.hk">exsspsr@wsd.gov.hk</a>).</li>
                    <li className="p-2">The maximum number of visitors during the visiting time is 100.</li>
                    <li className="p-2">WSD may adopt suitable crowd control measures to maintain order during the visit whenever necessary. If the number of visitors exceeds the upper limit, we will arrange the admission on a first come, first served basis according to the capacity of the venue. Visitors are required to follow the instructions given by our staff and wait at the entrance for admission.</li>
                    <li className="p-2">Visitors should ensure that their health conditions are good on the date of visit. If there is any discomfort or obvious symptoms (e.g. fever, cough, shortness of breath, vomiting, diarrhea, etc.) have been developed, it is not recommended to participate in the visit. </li>
                    <li className="p-2">For safety reasons, our staff reserve the right to end a visit if they observe any symptoms of infectious diseases in a visitor at any time.</li>
                    <li className="p-2">Toilet will not be provided for public in the venue. Visitors should use the toilets nearby before attend the visit.</li>
                    <li className="p-2">No storage facility will be provided in the venue. Visitors should take all their personal belongings with them during the visit. Please do not bring any sizable bags or luggage to the venue. Visitors carrying any article which in the opinion of WSD staff may cause annoyance or inconvenience to other visitors, WSD staff has the right to refuse entry of any persons or ask them to leave the site immediately if they refuse to abide the rules.</li>
                    <li className="p-2">Venue is under security camera surveillance and recording.</li>
                    <li className="p-2">The venue will remain closed if Tropical Cyclone Warning Signal No.3 or above; or Amber Rainstorm Warning Signal or above is in force before opening hours. It will be reopened as soon as possible after the signal is lowered. If the warning is cancelled less than 1 hours before the normal closing time, the venue will remain closed. If the warning is issued during the opening hours, on-site visits will be suspended. The venue will maintain limited operations until the condition is safe for visitors to leave the venue. Under normal circumstances, The latest notice will be announced in the programme website at appropriate time.</li>
                    <li className="p-2">WSD will decide to continue or suspend the visit depending on the weather or the actual situation of the venue.</li>
                    <li className="p-2">For enquiries, please contact the Water Supplies Department via the inquiry hotline (6679 6445) or email (<a className="cursor-pointer text-blue-600" href="mailto:exsspsr@wsd.gov.hk">exsspsr@wsd.gov.hk</a>). The operation hour of the enquiry hotline shall be 9:00 a.m. to 5:00 p.m. every day.</li>
                </ol>
                <div className="mt-10 mb-6"><p>
                    (II) Recommended visitors’ equipment:
                </p></div>
                <ol className="ml-5 list-decimal list-outside">
                    <li className="p-2">Please wear suitable clothes and sport shoes</li>
                    <li className="p-2">Anti-epidemic supplies (including hand sanitizer and disinfectant wet tissues)</li>
                    <li className="p-2">Anti-mosquito products (such as mosquito repellent)</li>
                    <li className="p-2">Personal mobile devices with the function to access internet and scan QR code (for listening to the audio guide of the venue)</li>
                    <li className="p-2">Earphones (to connect visitors’ personal mobile devices in order to listen the audio guide)</li>
                    <li className="p-2">Rain gear</li>
                    <li className="p-2">Drinking water</li>
                </ol>
            </div>
        },
        {
            'title': 'House Rules',
            'content': <div>
                <div className="mb-6"><p>
                    WSD endeavours to offer a safe environment and enjoyable experience for all of our visitors. Visitors are responsible for their behavior at all times. Please note the following:
                </p></div>
                <ol className="ml-5 list-decimal list-outside">
                    <li className="p-2">Visitors under 12 will only be allowed to visit when accompanied by an adult. Please take care of your children. </li>
                    <li className="p-2">Except guide dogs, hearing assistance dogs or service dogs, pets are not allowed to enter the Ex-SSPSR.</li>
                    <li className="p-2">WSD currently only allows restricted opening of the Ex-SSPSR for visiting by the public. Visitors are not allowed to take on the visit for other profit-making or non-profit-making purposes, such as street performances, outdoor shows, commercial photography, videography etc. WSD staff has the right to refuse entry of any persons or ask them to leave the site immediately if they refuse to abide the rules.</li>
                    <li className="p-2">Visitors are prohibited from using flash-light, lighting apparatus, drones, selfie stick and tripods to take photos/videos, or taking wedding, graduation or other styled photos during the visit in order to avoid any disturbance to other visitors.</li>
                    <li className="p-2">Please follow WSD staff instructions during the visit. Visitors should not run, play, make excessive noise, climb up any blocks/ structures or do not get into forbidden areas (e.g. areas fenced off by railings) to stay safe. WSD will not be responsible for any injuries. </li>
                    <li className="p-2">Visitors are reminded to maintain good personal hygiene and appropriate social distancing throughout the visit. Litter should be taken away by visitors.</li>
                    <li className="p-2">Eating (except drinking water and taking medicine), alcoholic beverages, smoking, hazardous or inflammable item are not allowed.</li>
                    <li className="p-2">Visitors should leave the venue before the visiting time ends. Do not linger in the venue after that.</li>
                    <li className="p-2">Throwing objects, employing foul language and behaving in a disorderly manner in the venue are not allowed.</li>
                    <li className="p-2">Cycling, skateboarding, rollerblading or ball games are not allowed.</li>
                    <li className="p-2">Visitors are not allowed to set up tents for camping or picnicking in the venue.</li>
                    <li className="p-2">Flying kite and model aircraft inside the venue are not allowed.</li>
                    <li className="p-2">Loudhailers should not be used in the venue.</li>
                    <li className="p-2">Visitors should not display banners, buntings, flyers or publicity materials of any kind in the venue.</li>
                    <li className="p-2">Unauthorised events, demonstrations, speeches debates, public gatherings and other public performance are not allowed.</li>
                    <li className="p-2">Begging or soliciting alms, gambling, touting, or any unlawful or immoral activities are prohibited.</li>
                    <li className="p-2">Promotional materials, souvenirs and give-away items should not be distributed or sold in the venue.</li>
                    <li className="p-2">WSD is exempted from all the liabilities of claims, costs, damages and expenses in respect of any damage, injury or loss to the visitors or their properties occasioned by or in any way arising from the visit or any purpose pertaining to or associated with the visit. Please keep your personal belongings by yourself.</li>
                    <li className="p-2">Visitors should not remove, disturb or otherwise interfere with any facility or item.</li>
                    <li className="p-2">Please do not perform any actions that could potentially harm any surroundings or any part of structures throughout the visit (e.g. drawing on the wall, kicking the stone pillar, etc).  WSD reserves the right to claim compensation for any loss or damage.</li>
                    <li className="p-2">WSD staff may take reasonable steps to stop participants from doing any act which, in his opinion, is likely to cause damage to any facility or item in the venue.</li>
                    <li className="p-2">Visitors should not wilfully obstruct any WSD staff in performing his duty or wilfully obstruct, disturb, interrupt or annoy any other visitors in the venue.</li>
                    <li className="p-2">WSD staff has the right to refuse entry of any persons or ask them to leave the site immediately if they refuse to abide the rules.</li>
                    <li className="p-2">WSD reserves the right and is entitled to change, alter, add, delete, revise and update all information on this “House Rules” at any time at its absolute discretion without giving any reasons and prior notice.</li>
                </ol></div>
        },
        {
            'title': 'Personal Data Collection Statement',
            'content': <div>
                <p>The Water Supplies Department of the Government of the Hong Kong Special Administrative Region ("WSD") is concerned to ensure that all personal data submitted through WSD’s “Ex-Sham Shui Po Service Reservoir” Internet website are handled in accordance with the relevant provisions of the <a className="cursor-pointer text-blue-600" href="https://www.elegislation.gov.hk/hk/cap486!en" target="_blank">Personal Data (Privacy) Ordinance (PDPO)</a>.</p>
                <p className='text-left text-3xl font-bold py-6 text-wsd'>Collection of Personal Data</p>
                <p>The WSD will record visits to our Internet website without collecting any personal identifiable information of users. Such general statistics are collected for the compilation of statistical reports and the diagnosis of problems with or concerning computer systems to help improve our Internet website.</p>
                <p>WSD collects personal information when you register with our online services. Personal information may include name, HKID Number, telephone number, mailing address, e-mail address or account number on water bill. The provision of information is voluntary. If you do not provide sufficient information, we may not be able to process your application or request. Please ensure that the information provided is accurate. The WSD will specify the collection purpose and intended usage of your data when it invites you to provide such information and advise you how you may request access to or correction of the personal data provided.</p>
                <p className='text-left text-3xl font-bold py-6 text-wsd'>Use of Personal Data</p>
                <p>WSD uses personal information for the following general purposes: fulfill your requests for services, improve our services, contact you, conduct research, and provide anonymous reporting for internal use etc.</p>
                <p>The information provided by you may be disclosed to other Government bureaux, departments and related organisations for the purpose mentioned above. Unless permitted or required by law, WSD will not disclose your personal data to other non-related third parties without your prior consent.</p>
                <p className='text-left text-3xl font-bold py-6 text-wsd'>Access to Personal Data</p>
                <p>Under the PDPO, you have the right to request access to or correction of the personal data provided. Such requests should be made in the <a className="cursor-pointer text-blue-600" href="https://www.pcpd.org.hk/english/publications/files/Dforme.pdf" target="_blank">Data Access Request Form OPS003</a> and sent to us <a className="cursor-pointer text-blue-600" href="https://www.wsd.gov.hk/en/contact-us/in-writing/index.html" target="_blank">by mail</a> to the Departmental Secretary of the Water Supplies Department. By virtue of Section 20(3)(e) of the PDPO, a data access request may be refused if it is not made in this form. The Form is available in the Reception Counter of the <a className="cursor-pointer text-blue-600" href="https://www.wsd.gov.hk/en/contact-us/offices/index.html" target="_blank">Water Supplies Department Head Office</a> and any District Office or can be downloaded at Office of the <a className="cursor-pointer text-blue-600" href="https://www.pcpd.org.hk/" target="_blank">Privacy Commissioner for Personal Data's website</a></p>
                <p className='text-left text-3xl font-bold py-6 text-wsd'>Security</p>
                <p>WSD uses SSL protocol to encrypt data during network transmission to protect your personal data. All personal data you provide to the WSD via our Internet website are secured, and access to them is restricted to authorised personnel only.</p>
            </div>
        }
    ],
    sc: [
        {
            'title': '参观人士须知',
            'content': <div>
                <div className="mb-6 break-all"><p>
                    (I)  注意事项:
                </p></div>
                <ol className="ml-5 list-decimal list-outside">
                    <li className="p-2">从巴域街出发至前深水埗配水库需步行约15分钟，路程主要为狭窄山路及梯级，需要一定的体力，参观人士应衡量个人实际身体状况，并考虑是否需要由照料者陪同和协助参观；参观人士于整个行程中有责任对其自身安全负责。如有特别需要人士有兴趣参观前深水埗配水库，请透过查询热线 （6679 6445）或电邮（<a className="cursor-pointer text-blue-600" href="mailto:exsspsr@wsd.gov.hk">exsspsr@wsd.gov.hk</a>）与水务署联络。</li>
                    <li className="p-2">参观人数上限为100人。</li>
                    <li className="p-2">为维持秩序，本署会视乎需要而採取适当的人流控制措施。如当日参观人数超出上限，本署将因应场地可容纳人数，按先到先得方法安排参观人士入场。参观人士须按本署职员的指示于入口处等候安排进场。</li>
                    <li className="p-2">参观人士需确保参观当日自身健康状况良好；如有任何身体不适或出现明显的病徵（例如发烧、咳嗽、气促、呕吐和腹泻等），均不宜前往参观。</li>
                    <li className="p-2">如本署职员于任何时候观察到参观人士的健康状况出现任何传染病徵状，本署职员有权终止其参观活动，以策安全。</li>
                    <li className="p-2">场内不设公众洗手间。如有需要，参观人士应预先前往就近相关设施。</li>
                    <li className="p-2">场内不设寄存设施，参观人士需随身携带所有个人物品进行参观。参观人士不可携带任何大型提包及行李进入场地。如本署职员认为参观人士所携带物品对其他使用者造成烦扰或不便，本署职员有权拒绝违规者进场或要求其立即离开场地。</li>
                    <li className="p-2">场地为保安电视监察及摄錄地带。</li>
                    <li className="p-2">如遇3号或以上热带气旋警告信号; 或黄色或以上暴雨警告信号，场地将不会开放。待警告信号解除后，场地将会尽快重新开放。若警告信号取消时距离关闭时间不足1小时，当日将不会开放。若警告信号在场地开放后始生效，场内参观将会暂停。场内会维持有限度运作，直至参观人士可以安全离开为止。有关恶劣天气的最新安排会适时透过本活动网页公布，敬请留意。</li>
                    <li className="p-2">本署会视乎天气或场地实际情况决定开放或关闭场地。</li>
                    <li className="p-2">如有疑问，请致电查询热线6679 6445或电邮至 <a className="cursor-pointer text-blue-600" href="mailto:exsspsr@wsd.gov.hk">exsspsr@wsd.gov.hk</a> 与水务署联络。查询热线的服务时间为每日上午9时至下午5时。</li>
                </ol>
                <div className="mt-10 mb-6 break-all"><p>
                    (II) 参观人士个人装备建议：
                </p></div>
                <ol className="ml-5 break-all list-decimal list-outside">
                    <li className="p-2">穿着合适衣服及运动鞋</li>
                    <li className="p-2">防疫用品（包括搓手液和消毒湿纸巾等）</li>
                    <li className="p-2">防蚊用品（例如：蚊怕水）</li>
                    <li className="p-2">配备上网及扫描二维码功能的个人通讯设备（以收听场地语音导航）</li>
                    <li className="p-2">耳筒（以接驳参观人士的个人通讯设备收听语音导航）</li>
                    <li className="p-2">雨具</li>
                    <li className="p-2">饮用水</li>
                </ol>
            </div>
        },
        {
            'title': '场地守则',
            'content': <div>
                <div className="mb-6 break-all"><p>
                本署致力提供一个安全及舒适的参观场地予所有参观人士。参观人士需要负责自己的个人行为，并请遵守以下场地守则：
                </p></div><ol className="ml-5 break-all list-decimal list-outside">
                <li className="p-2">未满12岁的参观人士必须由成人陪同方可进入，请小心看顾随行儿童。</li>
                <li className="p-2">除导盲犬、助听犬及服务犬外，请勿携带任何宠物进入前深水埗配水库。</li>
                <li className="p-2">前深水埗配水库目前只开放作参观之用，本署不接受参观人士于参观期间作其他牟利或非牟利用途，例如街头表演、户外节目、商业摄影和录像等。本署职员有权拒绝违规者进场或要求立即离开场地。</li>
                <li className="p-2">为避免影响其他参观人士，场地内禁止使用闪光或照明器具、无人机航拍、自拍杆和脚架拍照或录影，以及进行婚纱照、毕业照或其他造型照等拍摄或录影活动。</li>
                <li className="p-2">参观活动期间请听从本署职员指示。参观人士请勿在场内奔跑、嬉戏、喧哗、攀爬场内石块／构建物，或进入任何不向公众开放的范围（例如围栏围封范围），免生危险。如有损伤，本署恕不负责。</li>
                <li className="p-2">参观人士于参观期间必须保持良好个人衞生及适当社交距离，并将个人垃圾带走。</li>
                <li className="p-2">参观人士不得在场内进食（喝清水及服用药品除外）、饮酒、吸烟或携带有害或易燃物品。</li>
                <li className="p-2">参观人士须于参观时间完结前离开场地，请勿逗留。</li>
                <li className="p-2">参观人士不得在场内抛掷物件、粗言秽语或行为不检。</li>
                <li className="p-2">参观人士不得在场内踏单车、滑板、进行滚轴熘冰、或任何球类活动。</li>
                <li className="p-2">参观人士不得在场内搭建帐篷露营及野餐。</li>
                <li className="p-2">参观人士不得在场内放风筝或操作模型飞机。</li>
                <li className="p-2">参观人士不得在场使用扬声器。</li>
                <li className="p-2">参观人士不得在场内展示横额、彩旗、宣传单张或任何形式之广告。</li>
                <li className="p-2">参观人士不得在场内进行未经授权的活动、示威、演讲辩论、公众集会或其他公开表演。</li>
                <li className="p-2">严禁行乞、收取施捨、赌博、兜售或进行任何非法或不道德的活动。</li>
                <li className="p-2">参观人士不得在场内派发或售卖推广资料、纪念品及赠品。</li>
                <li className="p-2">请自行保管私人物品，如有任何损毁、损伤或损失，本署均毋须负起任何索偿、费用、毁坏及开支的责任。</li>
                <li className="p-2">参观人士不得擅自取走、扰乱或以其他方式干预场内任何设施或物品。</li>
                <li className="p-2">参观期间请勿做出任何行为（例如在牆上涂鸦，脚踢石柱等等）会潜在损害沿途环境或建筑物的任何一部份及周边范围。如有损坏，本署有权作出追究及要求作出赔偿。</li>
                <li className="p-2">如本署职员认为参观人士相当可能导致场内任何一部份设施或物品损毁，本署职员有权採取合理措施阻止。</li>
                <li className="p-2">参观人士不得故意妨碍任何正在执行职责的本署职员，或故意妨碍、骚扰、干扰或烦扰任何其他场内的参观人士。</li>
                <li className="p-2">本署职员有权拒绝违规者进场或要求其立即离开场地。</li>
                <li className="p-2">本署保留权利及绝对酌情权随时改变、更改、增补、删除、修订及更新此场地守则而无须给予任何理由及预先通知。</li>
            </ol></div>
        },
        // {
        //     'title': '申请方法',
        //     'content': <div><ol className="ml-5 break-all list-decimal list-outside">
        //         <li className="p-2">请于参观时段前透过网上预约系统申请。预约系统会显示自助导赏游约2个月内可以接受预约的参观时段，并会于每月的第一个工作日上午九时更新。</li>
        //         <li className="p-2">本署只接受参加者<strong>30天</strong>内以自助导赏游形式参观前深水埗配水库一次。为使更多人士有机会参与此参观活动，申请者及其成员成功参与自助导赏游后不得于<strong>30天</strong>内重複报名参观活动。所有申请记录最迟会于每月的第一个工作日上午六时三十分前更新。</li>
        //         <li className="p-2">如个别人士需要本署协助办理网上预约，或对报名、更改资料、场地或活动资讯有疑问，请致电查询热线6679 6445或电邮（self_guided_exsspsr@wsd.gov.hk）与水务署联络。查询热线的服务时间为每日上午九时至下午五时。</li>
        //     </ol></div>
        // },
        // {

        //     'title': '申请指引及参观规则',
        //     'content': <div><ol className="ml-5 break-all list-decimal list-outside">
        //         <li className="p-2">参加者必须于参观当日年满8岁；未满16岁的参加者必须由成人陪同参加。另外，参加者不可携同婴儿进入前深水埗配水库。</li>
        //         <li className="p-2">每节参观时段的参观人数上限为70人。</li>
        //         <li className="p-2">申请者必须年满16岁，每次申请参加人数（包括申请者）最多为4人，申请确认短讯及电子入场门券会一併发送至申请者的手提电话号码。</li>
        //         <li className="p-2">参加者若提供不完整或失实资料，将会被取消资格。</li>
        //         <li className="p-2">如有特别需要人士有兴趣参与自助导赏游，请透过查询热线 （6679 6445）或电邮（self_guided_exsspsr@wsd.gov.hk）与水务署联络。从巴域街出发至前深水埗配水库需步行约15分钟，路程主要为狭窄山路及梯级，需要一定的体力，申请者于申请时应衡量个人及其成员实际身体状况，考虑是否适合参加该自助导赏游及需要由照料者陪同和协助参加自助导赏游；参加者于整个行程中有责任对其自身安全负责。本署职员有权因应当时实际情况及需要拒绝任何人士参与活动。</li>
        //         <li className='p-2'>申请者或参加者如欲取消已预约之参观或更改参加人数，必须最迟于参观时段前透过网上预约系统取消预约。如申请者或参加者未依预约之参观时段出席活动，本署有权拒绝相关人士日后参观水务设施的申请或延长受影响的申请期限。如申请者或参加者因身体不适或突发事件而未能依预约之参观时段出席活动，需提供合理缺席原因的相关证明（例如：医生纸等）供本署确认以更新有关的申请纪录。</li>
        //         <li className="p-2">所有参观预约不可转让。一经发现，本署将取消相关人士的参观资格及不作另行通知。</li>
        //         <li className="p-2">为使更多人士有机会参与此参观活动，申请者及其成员成功参与自助导赏游后不得重複报名30天内的参观活动。一经发现，本署将会取消相关人士参观的资格及不作另行通知。</li>
        //         <li className="p-2">参加者须自行安排交通工具前往参观地点。</li>
        //         <li className="p-2">参加者须依所预约日期及时间莅临前深水埗配水库参观，并于入口处向本署职员出示电子入场门券。场内登记处会于该参观时段开始前15分钟开放，参加者须按本署职员要求展示申请确认短讯、电子入场门券及身份证明文件以作核实。如有需要，本署职员亦会致电确认参加者身分。</li>
        //         <li className="p-2">参加者须于所选择的参观时段开始后30分钟内进场，并于所选参观时段完结前离开场地，请勿逗留。本署不会为过时入场申请者作出补团或特别安排。</li>
        //         <li className="p-2">若3号或以上热带气旋警告信号; 或黄色或以上暴雨警告信号于参观活动开始前3小时内仍然生效，当天的参观活动将会自动取消。有关活动取消资讯会适时透过本活动网页发布。在一般情况下，本署将于下一个工作天早上更新有关的申请纪录，以便受影响的参加者可尽快再透过网上预约系统申请参观活动。</li>
        //         <li className="p-2">本署会视乎天气或场地实际情况决定继续或暂停参观活动。</li>
        //         <li className="p-2">本署有权取消任何预先安排的参观活动，有关活动取消资讯会适时透过本活动网页发布。本署不会为受活动取消影响的申请者作出补团或特别安排。</li>
        //         <li className="p-2">前深水埗配水库目前只开放作导赏团及自助导赏游之用，本署不接受参加者藉参与自助导赏游作其他牟利或非牟利用途，例如街头表演、户外节目、商业摄影和录像等。本署职员有权拒绝违规者进场或要求立即离开场地。</li>
        //         <li className="p-2">为了不影响参观活动，参加者于参观期间禁止使用无人机航拍和脚架拍照或录影。</li>
        //         <li className="p-2">除导盲犬外，请勿携带任何宠物进入前深水埗配水库。</li>
        //         <li className="p-2">场内不设寄存设施，参加者需随身携带所有个人物品进行参观。参加者不可携带任何大型提包及行李进入场地。如本署职员认为参加者所携带物品对其他使用者造成烦扰或不便，本署职员有权拒绝违规者进场或要求其立即离开场地。</li>
        //         <li className="p-2">本署将于参观活动期间进行拍摄及录影，有关之拍摄相片及录影片段或会作日后推广及宣传之用。参加者如不同意上述安排请告知本署职员并签署「不授权活动照片及录影片段作宣传用途」表格。</li>
        //         <li className="p-2">凡由参观活动或任何与此有关或连带的目的所引起或引致对参加者或其财产造成的任何损毁、损伤或损失，本署均毋须负起任何索偿、费用、毁坏及开支的责任。</li>
        //         <li className="p-2">如对本活动有其他建议，请电邮至<a className="cursor-pointer text-blue-600" href="mailto:self_guided_exsspsr@wsd.gov.hk">self_guided_exsspsr@wsd.gov.hk</a>。</li>
        //     </ol></div>
        // },
        // {
        //     'title': '参加者须知及场地守则',
        //     'content': <div>
        //         <span>本署致力提供一个安全及舒适的参观场地予所有参观者。参加者需要负责自己的个人行为，并请留意以下参加者须知及遵守场地守则：</span>
        //         <ol className="break-all list-none list-inside">
        //             <li className="p-4">(I) 参观前注意事项：
        //                 <ul className="ml-5 pl-3 mt-2 list-decimal list-outside">
        //                     <li className="p-1">场地为保安电视监察及摄錄地带。</li>
        //                     <li className="p-1">参加者需确保参观活动当日自身健康状况良好；如有任何身体不适或出现明显的病徵（例如发烧、咳嗽、气促、呕吐和腹泻等），均不宜参与活动。</li>
        //                     <li className="p-1">如本署职员于任何时候观察到参加者的健康状况不宜参与当日活动或出现任何传染病徵状，本署职员有权终止其参与活动，以策安全。本署并不会为该参加者作出补团或特别安排。</li>
        //                     <li className="p-1">本署职员会于入口处量度参加者的体温，如参加者的体温高于摄氏37.5度，本署职员会终止其参与参观活动。</li>
        //                     <li className="p-1">场内不设公众洗手间。如有需要，参加者应预先前往就近相关设施。</li>
        //                     <li className="p-1">场内不设寄存设施，参加者需随身携带所有个人物品进行参观。参加者不可携带任何大型提包及行李进入场地。如本署职员认为参加者所携带物品对其他使用者造成烦扰或不便，本署职员有权拒绝违规者进场或要求立即离开场地。</li>

        //                 </ul>
        //             </li>
        //             <li className="p-4">(II) 参加者个人装备建议：
        //                 <ul className="ml-5 pl-3 mt-2 list-decimal list-outside">
        //                     <li className="p-1">穿着合适衣服及运动鞋</li>
        //                     <li className="p-1">个人清洁用品（如搓手液和消毒纸湿巾等）</li>
        //                     <li className="p-1">防蚊用品（例如：蚊怕水）</li>
        //                     <li className="p-1">配备上网及扫描二维码功能的个人通讯设备（以收听自助导赏系统的语音导航）</li>
        //                     <li className="p-1">耳筒（以接驳参加者的个人通讯设备收听语音导航）</li>
        //                     <li className="p-1">雨具</li>
        //                     <li className="p-1">饮用水</li>
        //                 </ul>
        //             </li>
        //             <li className="p-4">(III) 参观期间注意事项：
        //                 <ul className="ml-5 pl-3 mt-2 list-decimal list-outside">
        //                     <li className="p-1">参观活动期间请听从本署职员指示。参加者请勿在场内奔跑、攀爬场内石块／构建物，或进入任何不向公众开放的范围（例如围栏围封范围），免生危险。如有损伤，本署恕不负责。</li>
        //                     <li className="p-1">参加者于活动举行期间须注意安全及小心保管个人财物，如有遗失及损坏本署恕不负责。</li>
        //                     <li className="p-1">参加者不得在参观期间饮食（喝清水及服用药品除外）。</li>
        //                     <li className="p-1">参加者于参观期间必须保持良好个人衞生及适当社交距离，并将个人垃圾带走。</li>
        //                     <li className="p-1">参加者不得在场内进食、饮酒、吸烟、奔跑、喧哗或携带有害或易燃物品。</li>
        //                     <li className="p-1">参加者须于参观时段完结前离开会场，请勿逗留。</li>
        //                     <li className="p-1">参加者不得擅自取走、扰乱或以其他方式干预场内任何设施或物品。</li>
        //                     <li className="p-1">参加者不得在场内抛掷物件、粗言秽语或行为不检。</li>
        //                     <li className="p-1">参加者不得在场内踏单车、滑板、进行滚轴熘冰、或任何球类活动。</li>
        //                     <li className="p-1">参加者不得在场使用扬声器。</li>
        //                     <li className="p-1">参加者不得在场内展示横额、彩旗、宣传单张或任何形式之广告。</li>
        //                     <li className="p-1">参加者不得在场内进行未经授权的活动、示威、演讲或其他公开表演。</li>
        //                     <li className="p-1">参加者不得在场内派发或售卖推广资料、纪念品及赠品。</li>
        //                     <li className="p-1">活动期间请勿做出任何行为（例如在牆上涂鸦，脚踢石柱等等）会潜在损害沿途环境或建筑物的任何一部份及周边范围。如有损坏，本署有权作出追究及要求作出赔偿。</li>
        //                     <li className="p-1">如本署职员认为参加者相当可能导致场内任何一部份设施或物品损毁，本署职员有权採取合理措施阻止。</li>
        //                     <li className="p-1">参加者不得故意妨碍任何正在执行职责的本署职员，或故意妨碍、骚扰、干扰或烦扰任何其他场内的参加者。</li>
        //                     <li className="p-1">本署职员有权拒绝违规者进场或要求其立即离开场地。</li>
        //                     <li className="p-1">本署保留权利及绝对酌情权随时改变、更改、增补、删除、修订及更新此场地守则而无须给予任何理由及预先通知。</li>
        //                 </ul>
        //             </li>
        //         </ol></div>
        // },
        {
            'title': '个人资料收集声明',
            'content': <div className="break-all">
                <p>香港特别行政区政府水务署（下称「本署」）会确保所有透过水务署的「前深水埗配水库」互联网网站（下称「本网站」）递交的个人资料，均按照<a className="cursor-pointer text-blue-600" href="https://www.elegislation.gov.hk/hk/cap486!zh-Hant-HK" target="_blank" title="香港法例网页，此超连结会在新视窗开启">《个人资料（私隐）条例》</a>的有关条文处理。</p>
                <p className='text-3xl font-bold py-6 text-wsd'>个人资料的收集</p>
                <p>本署会记录使用者浏览本网站的次数，但并不会收集任何足以辨识使用者身份的资料。所收集的浏览次数纪录只会用于製作统计报告及调查电脑系统问题，以助本署改善本网站的服务。</p>
                <p>每当你使用我们的网上服务，本署都会收集相关的个人资料。此等资料可能包括姓名、身份证号码、电话号码、邮寄地址、电邮地址或水费单上的用户编号。你提供资料完全出于自愿。如资料不全，本署可能无法处理你的申请。请确保你所提供的资料正确。本署邀请你提供资料时，会列明收集资料的目的和用途，并会告知你如何要求查阅或改正所提供的个人资料。</p>
                <p className='text-3xl font-bold py-6 text-wsd'>个人资料的使用</p>
                <p>本署会使用你所提供的资料作下列一般用途：提供你所要求的服务、改善我们的服务、联络阁下、进行研究调查、及提供不记名的报告予内部使用等。</p>
                <p>你所提供的资料或会向政府其他决策局、部门以及其他相关机构公开，作上述用途。除非法律许可或有所规定，政府不会在未得到你的同意下透露你的个人资料予其他非相关的第三者。</p>
                <p className='text-3xl font-bold py-6 text-wsd'>查阅个人资料</p>
                <p>根据《个人资料（私隐）条例》，任何人均有权要求查阅或改正已向本署提供的个人资料。有关要求须透过<a className="cursor-pointer text-blue-600" href="https://www.pcpd.org.hk/tc_chi/resources_centre/publications/forms/files/Dformc.pdf" target="_blank" title="查阅资料要求表格OPS003，此超连结会在新视窗开启">查阅资料要求表格OPS003</a>提出，填妥表格后，请以<a className="cursor-pointer text-blue-600" href="https://www.wsd.gov.hk/tc/contact-us/in-writing/index.html" target="_blank">邮寄</a>方式寄交水务署公共关係组收。根据《个人资料（私隐）条例》第20(3)(e)条，如不使用上述表格提出申请，有关方面或会拒绝查阅资料的要求。上述表格可在<a className="cursor-pointer text-blue-600" href="https://www.wsd.gov.hk/tc/contact-us/offices/index.html" target="_blank" title="水务署网页，此超连结会在新视窗开启">水务署总部</a>及各区民政事务处索取，你也可在<a className="cursor-pointer text-blue-600" href="https://www.pcpd.org.hk/" target="_blank" title="个人资料私隐专员公署网页，此超连结会在新视窗开启">个人资料私隐专员公署网页</a>下载。</p>
                <p className='text-3xl font-bold py-6 text-wsd'>保安</p>
                <p>本网站使用SSL通讯协定传送网上登记的资料，以确保你的个人资料得到保护。本署会将你于本网站提供的个人资料保密，只有获得授权的人士才可查阅。</p>
            </div>
        }
    ],
}


export default function Intro(props) {
    const [searchParams] = useSearchParams();
    const [isNotiOpen, setIsNotiOpen] = useState(false);
    const [news, setNews] = useState('');
    // const [isNotiOpen, setIsNotiOpen] = useState(false);
    const [setTitle] = useOutletContext();
    const [isFetching, setIsFetching] = useState(false);
    const [alertStatus, setAlertStatus] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const { t, i18n } = useTranslation(['intro', 'errorMessage', 'page']);

    const cleanupTimeout = (timer) => {
        setIsFetching(false);
        return clearTimeout(timer)
    }

    const getSectionInfo = () => {
        if (searchParams.get('section')) {
            const section = searchParams.get('section');
            if (section.indexOf('-')) {
                const splits = searchParams.get('section').split('-');
                return [splits[0], isNaN(parseInt(splits[1])) ? 0 : parseInt(splits[1])];
            }
        }
        // default
        return ['method', 0];
    }

    useEffect(() => {
        document.title = t(props.title, { ns: 'page' });
        document.documentElement.lang = i18n.language === 'en' ? 'en' : i18n.language === 'tc' ? 'zh-HK' : 'zh-CN';
        document.getElementsByTagName('meta')["keywords"].content = t('meta-keywords', { ns: 'page' });
        document.getElementsByTagName('meta')["description"].content = t('meta-desc', { ns: 'page' });
        setTitle(document.title);

        const section = searchParams.get('section');
        if (section) {
            const [sectionId] = getSectionInfo();
            document.getElementById(sectionId).scrollIntoView()
            searchParams.delete('section');
        }

        trackGa(i18n);
    }, [i18n.language])

    return (
        <>
            <Notification
                isOpen={isNotiOpen}
                setIsOpen={setIsNotiOpen}
                hasSectionParam={!!searchParams.get('section')}
                news={news}
                setNews={setNews}
            />
            <Alert status={alertStatus} text={alertText} setShow={setShowAlert} show={showAlert} />
            {isFetching && <Loading />}
            <div className="mb-6">
                <div className="lg:flex lg:pb-10 lg:-mt-6">
                    <div className="relative w-full">
                        <img className="w-full hidden lg:block lg:translate-y-16" src="/img/banner-v3.png" alt="Banner of Ex-Sham Shui Po Service Reservoir" />
                        <img className="w-full block lg:hidden translate-y-4" src="/img/banner-v3-m.png" alt="Banner of Ex-Sham Shui Po Service Reservoir (Mobile)" />
                        {/* <img className="h-[480px] w-full lg:w-2/3 lg:translate-y-10" src="/img/intro-bg.png" alt="" />
                        <div className="absolute left-0 right-0 mx-auto top-8 px-10 py-4 lg:top-16 lg:left-32 lg:w-5/6">
                            <div className="h-fit items-center flex lg:flex-row flex-col rounded-[3.5rem] w-full bg-gradient-to-r from-wsd-cancel to-white lg:h-[370px]">
                                <img className="w-2/3 h-2/3 lg:w-3/4 lg:h-3/4 lg:pl-4" src="/img/intro-logo-a.png" alt="" />
                                <img className="w-2/3 h-2/3 lg:w-3/4 lg:h-3/4 lg:pr-4" src="/img/intro-logo-b.png" alt="" />
                            </div>
                        </div> */}
                    </div>

                    {/* <div className="lg:hidden w-full flex px-16 py-14 justify-center">
                        <img className="border" src="/img/enroll-poster.png" alt="Ex-SSPSR Guided Tour Poster" />
                    </div> */}
                    {/* <a aria-label="Ex-SSPSR Guided Tour Poster (Open In New Window)" href="/pdf/Ex-SSPSR-Poster.pdf" target="_blank">
                        <div className="lg:hidden flex justify-center mx-6 -mt-24">
                            <div className="max-w-md w-2/3 p-2 h-14 text-xl rounded-full bg-wsd text-white font-semibold shadow-md hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-yellow-500">
                                <div className="flex justify-center mt-2">
                                    <FontAwesomeIcon icon={faMagnifyingGlass} alt="Ex-SSPSR Guided Tour Poster (Open In New Window)" />
                                </div>
                            </div>
                        </div>
                    </a> */}

                    <div className="md:hidden bg-gray-50 pb-4">
                        <div className='lg:w-full lg:pl-2 pt-4'>
                            <div className="flex mx-6 mt-6 lg:mt-10 w-3/4">
                                <p className="text-3xl font-medium leading-6 text-wsd">{t('title', { ns: 'news' })}</p>
                            </div>
                            <div className="border-dashed border-t-2 border-wsd flex mx-6 mt-6 w-fit">
                                <span className="pt-4 inline-block text-sm"><div dangerouslySetInnerHTML={{ __html: sanitize(news?.content) }} /></span>
                            </div>
                        </div>
                    </div>


                    <div className="lg:mt-0 lg:w-5/6 relative text-justify pt-2">
                        <div className="absolute right-1 top-4 p-4">
                            <img className="w-16" src="/img/intro-corner.png" alt="" />
                        </div>
                        <div className="lg:flex">
                            <div className='lg:w-full lg:pl-2 py-8'>
                                <div className="flex mx-6 mt-6 lg:mt-10 w-3/4">
                                    <p className="inline-block font-bold text-xl">{t('first.title', { ns: 'intro' })}</p>
                                </div>
                                <div className="flex mx-6 mt-6 w-fit">
                                    <span className="inline-block text-sm">{t('first.desc-1', { ns: 'intro' })}<a className="underline underline-offset-4" href="https://www.wsd.gov.hk/VirtualTour/index.html" target="_blank">{t('first.link', { ns: 'intro' })}</a>{t('first.desc-2', { ns: 'intro' })}</span>
                                </div>
                            </div>
                            {/* <div className="hidden lg:block lg:flex-1">
                                <div className="w-56 flex mt-12 pr-10 py-10 justify-center">
                                    <div className="relative w-full border">
                                        <img src="/img/enroll-poster.png" alt="Ex-SSPSR Guided Tour Poster" />
                                        <a aria-label="Ex-SSPSR Guided Tour Poster (Open In New Window)" href="/pdf/Ex-SSPSR-Poster.pdf" target="_blank">
                                            <div className="absolute -bottom-9 w-40 left-3 p-2 h-14 text-xl rounded-full bg-wsd text-white font-semibold shadow-md hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-yellow-500">
                                                <div className="flex justify-center mt-2">
                                                    <FontAwesomeIcon icon={faMagnifyingGlass} alt="Ex-SSPSR Guided Tour Poster (Open In New Window)" />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* 
                <div className="lg:hidden flex px-4 justify-between h-12 mt-6 border-y-2">
                    <div className="flex flex-none items-center px-1">
                        <img className="w-8" src="/img/enroll-user.png" alt="" />
                        <span className="ml-6 inline-block text-md md:text-xl">報團</span>
                    </div>
                </div> */}

                <div className="hidden lg:flex flex px-4 justify-center mt-14">
                    <AnchorLink offset='20' href='#apply'>
                        <div tabIndex="1" className={`${i18n.language === 'en' ? 'h-40' : 'h-28'} w-44 cursor-pointer bg-blue-50 bg-opacity-50 hover:bg-blue-200 focus:bg-blue-200 p-4 flex flex-col items-center px-1`}>
                            <img className="w-9" src="/img/enroll-user.png" alt="Go To Submit Application Section" />
                            <span className="inline-block text-gray-600 mt-2 text-md">{t('tab.apply', { ns: 'intro' })}</span>
                        </div>
                    </AnchorLink>
                    <AnchorLink offset='20' href='#method'>
                        <div tabIndex="2" className={`${i18n.language === 'en' ? 'h-40' : 'h-28'} w-44 cursor-pointer bg-blue-50 bg-opacity-50 p-4 hover:bg-blue-200 focus:bg-blue-200 flex flex-col items-center px-1`}>
                            <img className="w-9" src="/img/enroll-apply.png" alt="Go To Application Details Section" />
                            <span className="inline-block text-gray-600 mt-2 text-md">{t('tab.method', { ns: 'intro' })}</span>
                        </div>
                    </AnchorLink>
                    <AnchorLink offset='20' href='#audio'>
                        <div tabIndex="3" className={`${i18n.language === 'en' ? 'h-40' : 'h-28'} w-44 cursor-pointer bg-blue-50 bg-opacity-50 p-4 hover:bg-blue-200 focus:bg-blue-200 flex flex-col items-center px-1`}>
                            <img className="w-9" src="/img/ic-audio.png" alt="Go To Audio Guide Section" />
                            <span className="text-center inline-block text-gray-600 mt-2 text-md"><p>{t('tab.audio', { ns: 'intro' })}</p><p className="text-gray-500 mt-1 text-sm">{t('tab.audio-desc', { ns: 'intro' })}</p></span>
                        </div>
                    </AnchorLink>
                </div>

                <div className={`bg-blue-800 lg:h-1/5 md:h-1/5 lg:pt-6 ${i18n.language === 'en' ? 'lg:pb-96' : 'lg:pb-80'}  py-6 w-full bg-no-repeat bg-bottom md:bg-center bg-cover md:bg-[url('enroll-bg.png')] bg-[url('enroll-mobile-bg.png')] ${i18n.language === 'en' ? 'h-[1200px]' : 'h-[1050px]'}`}>
                    <div className="ml-6 md:ml-20 flex justify-start md:justify-center mt-2 mx-6">
                        <div className="md:w-44 w-2/5 p-1 text-center text-md md:text-2xl rounded-full border-2 border-white bg-wsd text-white font-semibold shadow-md">
                            {t('visit-time.title', { ns: 'intro' })}
                        </div>
                    </div>

                    {/* <div className="mx-6 md:mx-0 mt-4 flex flex-none md:justify-center items-center text-justify">
                        <img className="mt-1 w-8" src="/img/ic-speaker.png" alt="" />
                        <p className="pr-2 mt-0.5 ml-4 block text-white text-md md:text-sm">{t('new-visit-time.title', { ns: 'intro' })}</p>
                    </div> */}

                    <div className="mt-6 mx-6 md:mx-0 flex flex-none md:justify-center items-center text-justify">
                        <span className="ml-1 md:ml-16 block text-white font-bold">{t('new-visit-time.bold', { ns: 'intro' })}</span>
                    </div>

                    <div className="w-full h-96 md:h-[8rem]">
                        <div className={`flex justify-between md:justify-center items-center mt-3 md:mr-20`}>
                            <div className={`${i18n.language === 'en' ? '-translate-y-0' : '-translate-y-0'} flex justify-center ml-2 w-1/3 max-w-[120px]`}>
                                {i18n.language === 'tc' && <img className="w-[75px]" src="/img/timeball-ampm-set.png" alt="" />}
                                {i18n.language === 'en' && <img className="w-[75px]" src="/img/timeball-ampm-set-en.png" alt="" />}
                                {i18n.language === 'sc' && <img className="w-[75px]" src="/img/timeball-ampm-set-sc.png" alt="" />}
                            </div>
                            <div className="text-center ml-2 md:max-w-md w-full space-y-[23px] md:space-y-4 flex flex flex-col text-white text-sm">
                                <div className="md:px-10 md:py-3 py-2 border-l-2 border-t-2 border-b-2 rounded-l-full md:rounded-full md:border-2">
                                    <span className="block">{t('new-visit-time.am', { ns: 'intro' })}</span>
                                    <span className="text-[12px] block">{t('new-visit-time.am-desc', { ns: 'intro' })}</span>
                                </div>
                                <div className="md:px-10 md:py-3 py-2 border-l-2 border-t-2 border-b-2 rounded-l-full md:rounded-full md:border-2">
                                    <span className="block">{t('new-visit-time.pm', { ns: 'intro' })}</span>
                                    <span className="text-[12px] block">{t('new-visit-time.pm-desc', { ns: 'intro' })}</span>
                                </div>

                            </div>
                        </div>

                        <div className="mx-6 md:mx-0 mt-10 flex flex-none md:justify-center items-center">
                            <img className="w-8 -mt-1" src="/img/ic-closed.png" alt="" />
                            <p className="mt-1 ml-4 block text-white font-bold text-md md:text-sm">{t('new-visit-time.out-of-service', { ns: 'intro' })}</p>
                        </div>

                        <div className="mt-6 flex justify-center text-white items-center text-[12px]">
                            <span className="px-10 md:px-0 md:w-2/4 block">
                                <p className="mb-4">{t('visit-time.ps-title', { ns: 'intro' })}</p>
                                <p class="max-w-[720px]">{t('visit-time.ps-1', { ns: 'intro' })}</p><br />
                            </span>
                        </div>


                    </div>


                </div>

                {/* start of enrollment */}
                <div className="lg:flex lg:flex-row-reverse bg-blue-50 py-10 text-gray-600">
                    <div className="lg:w-1/2 lg:mt-6 lg:ml-6">
                        <div className="flex justify-start mt-2 mx-6">
                            <div className="w-fit py-1 px-5 text-center text-md md:text-2xl rounded-full border-2 border-wsd bg-white text-wsd font-semibold shadow-md">
                                {t('visit-place.title', { ns: 'intro' })}
                            </div>
                        </div>

                        <div className="relative text-justify pt-4">
                            <div className="absolute left-2 top-0 p-4">
                                <img className="w-8" src="/img/enroll-pin.png" alt="" />
                            </div>

                            <div className="ml-9">
                                <div className="flex mx-6">
                                    <p className="inline-block text-wsd font-bold text-xl">{t('visit-place.p1', { ns: 'intro' })}</p>
                                </div>

                                <div className="flex mx-6 mt-1">
                                    <span className="inline-block text-md">{t('visit-place.p1-desc', { ns: 'intro' })}</span>
                                </div>
                                <div className="flex mx-6 mt-1">
                                    <a className="text-blue-600 cursor-pointer" href="/pdf/Ex-SSPSR-Leaflet.pdf" target="_blank">
                                        <span className="inline-block text-md">{t('visit-place.p1-pdf', { ns: 'intro' })} <FontAwesomeIcon icon={faFilePdf} alt="Leaflet of Ex-Sham Shui Po Service Reservoir (Open in New Window)" />)</span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* <div className="flex justify-start mt-10 mx-6">
                            <div className="w-fit py-1 px-5 text-center text-md md:text-2xl rounded-full border-2 border-wsd bg-white text-wsd font-semibold shadow-md">
                                {t('visit-place.title-2', { ns: 'intro' })}
                            </div>
                        </div> */}

                        <div className="relative text-justify pt-4">
                            <div className="ml-9">
                                {/* <div className="flex mx-6">
                                    <span className="inline-block text-wsd font-bold text-xl">{t('visit-place.p2', { ns: 'intro' })}</span>
                                </div> */}

                                <div className="flex mx-6 mt-1">
                                    <span className="inline-block text-md">{t('visit-place.p2-desc', { ns: 'intro' })}</span>
                                </div>
                                <div className="flex mx-6 mt-1">
                                    <a className="text-blue-600 cursor-pointer" href="/pdf/Ex-SSPSR-Uphill-Route-Map.pdf" target="_blank">
                                        <span className="inline-block text-md">{t('visit-place.p2-pdf', { ns: 'intro' })} <FontAwesomeIcon icon={faFilePdf} alt="Uphill Route Map (Open in New Window)" />)</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="lg:w-1/3 lg:flex-col lg:justify-end ">
                        <div className="lg:flex lg:justify-end w-full lg:p-2 p-4 mt-4 lg:mt-0">
                            <img src="/img/enroll-map.jpg" alt="Route Map" />
                        </div>

                        <div className="text-justify pt-2">
                            <div className="flex flex-col mx-6 mt-1">
                                <span className="inline-block text-sm">{t('visit-place.ps-title', { ns: 'intro' })}</span>
                                <span className="inline-block text-sm">{t('visit-place.ps-1', { ns: 'intro' })}</span>
                                <span className="inline-block text-sm">{t('visit-place.ps-2', { ns: 'intro' })}</span>
                            </div>
                        </div>
                    </div>

                </div>

                <Detector />

                {/* <TimeTable />

                <div className="block lg:hidden w-full flex mt-4 justify-end text-gray-700">
                    <span className="mr-2">{t('table.swipe', { ns: 'intro' })}</span>
                    <span><FontAwesomeIcon className="animate-pulse mr-4 text-wsd w-6 h-6" icon={faRightLong} alt="Swipe The Time-table To View More" /></span>
                </div> */}

                {/* end of enrollment */}

                {/* <section id='apply'>
                    <div className="mt-6 flex justify-center">
                        <div className="max-w-xl w-full flex px-4 justify-between h-12 mt-6 border-y-2">
                            <div className="flex flex-none items-center px-1">
                                <img className="w-8" src="/img/enroll-user.png" alt="" />
                                <span className="ml-6 inline-block text-md md:text-xl">{t('tab.apply', { ns: 'intro' })}</span>
                            </div>
                        </div>
                    </div>
                </section>

                <TimeTable />

                <div className="block lg:hidden w-full flex mt-4 justify-end text-gray-700">
                    <span className="mr-2">{t('table.swipe', { ns: 'intro' })}</span>
                    <span><FontAwesomeIcon className="animate-pulse mr-4 text-wsd w-6 h-6" icon={faRightLong} alt="Swipe The Time-table To View More" /></span>
                </div>

                <div className="flex justify-center my-20">
                    <div className="flex flex-col w-80">
                        <img src="/img/mascot.png" alt="WSD Mascot" />
                        <div className="inline-block text-wsd font-bold text-3xl px-4 -mt-14">
                            {process.env.REACT_APP_IS_ALLOW_NEW_APPLICATION && <Link to="/reservation">
                                <div className="flex justify-center mt-6">
                                    <div className="flex justify-center h-14 w-full p-2 text-2xl rounded-full bg-wsd text-white font-bold shadow-md hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-yellow-500">
                                        {t('apply.reserve', { ns: 'intro' })}
                                    </div>
                                </div>
                            </Link>}
                            {!process.env.REACT_APP_IS_ALLOW_NEW_APPLICATION && <div className="flex justify-center mt-6">
                                <div className="flex cursor-not-allowed justify-center h-14 w-full p-2 text-2xl rounded-full bg-gray-500 text-white font-bold shadow-md">
                                    {t('apply.reserve', { ns: 'intro' })}
                                </div>
                            </div>}
                        </div>
                        <div className="inline-block">
                            {process.env.REACT_APP_IS_ALLOW_NEW_APPLICATION && <Link to="/cancel-reservation">
                                <div className="flex justify-center mt-6">
                                    <div className="flex justify-center bg-wsd-form w-48 px-42 py-2 text-xl rounded-full text-white font-bold shadow-md hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-yellow-500">
                                        {t('apply.cancel', { ns: 'intro' })}
                                    </div>
                                </div>
                            </Link>}
                            {!process.env.REACT_APP_IS_ALLOW_NEW_APPLICATION && <div className="flex justify-center mt-6">
                                <div className="flex cursor-not-allowed justify-center bg-gray-500 w-48 px-42 py-2 text-xl rounded-full text-white font-bold shadow-md=">
                                    {t('apply.cancel', { ns: 'intro' })}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>*/}


                <section id='method'>
                    {/* <div className="flex justify-center mt-16 w-full">
                        <div className="lg:w-56 max-w-2xl w-1/2 p-1 text-center text-md rounded-full border-2 border-wsd bg-white text-wsd font-semibold shadow-md">
                            <div className="flex px-2 justify-start">
                                <div className="flex flex-none items-center px-1">
                                    <div className="px-2 py-1 border-1 rounded-full text-wsd font-meidum">
                                        <img className="w-6 " src="/img/enroll-apply.png" alt="" />
                                    </div>
                                </div>

                                <div className="border-r-2 border-gray-100" />

                                <div className="flex flex-1 justify-center items-center px-1">
                                    <div className="py-1 border-1 rounded-full text-wsd font-xl">
                                        申請方法
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="mt-10 flex justify-center">
                        <div className="max-w-xl w-full flex px-4 justify-between h-12 border-y-2">
                            <div className="flex flex-none items-center px-1">
                                <img className="w-6 " src="/img/enroll-apply.png" alt="" />
                                <span className="ml-6 inline-block text-md md:text-xl">{t('tab.method', { ns: 'intro' })}</span>
                            </div>
                        </div>
                    </div>
                </section>


                <div className="lg:flex lg:justify-center w-full px-4 mt-4">
                    <div className="mx-auto md:w-3/4 w-full rounded-2xl bg-white p-2 my-4">
                        {applicationPoints[i18n.language].map((ap, idx) => {
                            return <Disclosure key={idx} defaultOpen={searchParams.get('section') ? idx === getSectionInfo()[1] : idx === 0}>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className={`${open ? 'rounded-t-3xl' : 'rounded-full'} ${i18n.language === 'en' ? 'text-left' : ''} mt-4 items-center flex w-full h-14 justify-between bg-wsd px-4 py-2 font-medium text-lg text-white`}>
                                            <span className="ml-2">{ap.title}</span>
                                            {!!open && <img className="w-6" src="/img/ic-collapse.png" alt="Collapse" />}
                                            {!open && <img className="w-4" src="/img/ic-expand.png" alt="Expand" />}
                                        </Disclosure.Button>
                                        <Transition
                                            enter="transition duration-100 ease-out"
                                            enterFrom="transform scale-95 opacity-0"
                                            enterTo="transform scale-100 opacity-100"
                                            leave="transition duration-75 ease-out"
                                            leaveFrom="transform scale-100 opacity-100"
                                            leaveTo="transform scale-95 opacity-0"
                                        >
                                            <Disclosure.Panel className="p-6 rounded-b-3xl text-justify text-md bg-blue-50 text-gray-600">{ap.content}
                                            </Disclosure.Panel>
                                        </Transition>
                                    </>
                                )}
                            </Disclosure>
                        })}

                    </div>
                </div>

                <section id='audio'>
                    {/* <div className="flex justify-center mt-16 w-full">
                        <div className="lg:w-56 max-w-2xl w-1/2 p-1 text-center text-md rounded-full border-2 border-wsd bg-white text-wsd font-semibold shadow-md">
                            <div className="flex px-2 justify-start">
                                <div className="flex flex-none items-center px-1">
                                    <div className="px-2 py-1 border-1 rounded-full text-wsd font-meidum">
                                        <img className="w-6 " src="/img/ic-audio.png" alt="" />
                                    </div>
                                </div>

                                <div className="border-r-2 border-gray-100" />

                                <div className="flex flex-1 justify-center items-center px-1">
                                    <div className="py-1 border-1 rounded-full text-wsd font-xl">
                                        語音導賞
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="mt-6 flex justify-center">
                        <div className={`max-w-xl w-full flex px-4 justify-between ${i18n.language === 'en' ? 'h-20' : 'h-14'} mt-6 border-y-2`}>
                            <div className="flex flex-none items-center px-1">
                                <img className="w-6 " src="/img/ic-audio.png" alt="" />
                                <div className="flex flex-col">
                                    <p className="ml-6 inline-block text-md md:text-xl">{t('tab.audio', { ns: 'intro' })}</p>
                                    {i18n.language !== 'en' && <p className="ml-6 mt-1 text-sm">{t('tab.audio-desc', { ns: 'intro' })}</p>}
                                    {i18n.language === 'en' && <p className="ml-6 mt-1 text-sm">{t('tab.audio-desc-mobile-1', { ns: 'intro' })}</p>}
                                    {i18n.language === 'en' && <p className="ml-6 mt-1 text-sm">{t('tab.audio-desc-mobile-2', { ns: 'intro' })}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="w-full p-4 mt-4">
                    <div className="relative w-full">
                        <img className="hidden lg:block w-full rounded-3xl" src="/img/audio-bg.png" alt="" />
                        <img className={`tablet:mx-auto tablet:w-4/5 lg:hidden w-full rounded-3xl`} src="/img/audio-mobile-bg.png" alt="" />
                        <div className={`absolute tablet:max-w-lg tablet:mx-auto lg:mx-4 tablet:bottom-20 inset-x-0 lg:left-20 lg:top-10 ${i18n.language === 'en' ? '-bottom-[10px]' : 'bottom-[1px]'} inline-block text-wsd font-bold text-3xl px-6`}>
                            <div className="flex text-3xl flex-col mx-2 justify-center">
                                <div className="flex mx-1 justify-start">
                                    <span>{t('audio.title', { ns: 'intro' })}</span>
                                </div>
                                <div className="flex mx-1 mt-2 justify-start">
                                    <span>{t('audio.desc', { ns: 'intro' })}</span>
                                </div>
                                {process.env.REACT_APP_IS_AUDIO_GUIDE_LAUNCHED && <a tabIndex="-1" href={`${process.env.REACT_APP_AUDIO_DOMAIN}/`}>
                                    <div className={`flex justify-center lg:w-[350px] h-14 w-full p-2 ${i18n.language === 'en' ? 'my-2' : 'mt-6'} text-2xl rounded-full bg-wsd text-white font-semibold shadow-md hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-yellow-500`}>
                                        {t('audio.enter', { ns: 'intro' })}
                                    </div>
                                </a>}
                                {!process.env.REACT_APP_IS_AUDIO_GUIDE_LAUNCHED && <div className={`flex cursor-not-allowed justify-center lg:w-[350px] h-14 w-full p-2 ${i18n.language === 'en' ? 'my-2' : 'mt-6'} text-2xl rounded-full bg-gray-500 text-white font-semibold shadow-md`}>
                                    {t('audio.coming-soon', { ns: 'intro' })}
                                </div>}
                                <div className={`flex text-gray-500 text-sm mx-1 ${i18n.language === 'en' ? 'mb-2' : 'mt-2'} font-medium justify-start`}>
                                    <span>{t('audio.ps', { ns: 'intro' })}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex w-[70px] ml-auto sticky bottom-20 lg:bottom-0 p-4 -mt-10 lg:-mt-28">
                    <AnchorLink aria-label="Back To Top" href='#top' className="items-center rounded-full border border-transparent bg-wsd-form p-3 text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2">
                        <ArrowUpIcon title="Back To Top" className="h-4 w-4 lg:h-6 lg:w-6" />
                    </AnchorLink>
                </div>

            </div >
        </>
    );
}
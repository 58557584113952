import { useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { useTranslation } from 'react-i18next';
import qs from 'qs'
import axios from 'axios'
import { sanitize } from 'dompurify';
import { Transition } from '@headlessui/react'
import { upperCase, head } from 'lodash';

import Popup from './Popup'
import { trackGaErr } from '../utils/ga'

export default function Notification(props) {
    const [isFetching, setIsFetching] = useState(true);
    const { setIsOpen, hasSectionParam, news, setNews } = props
    const { t, i18n } = useTranslation(['news']);

    function closeModal() {
        setIsOpen(false)
    }

    useEffect(() => {
        async function getNews() {
            setIsFetching(true);
            const query = qs.stringify({
                _where: [{ name_contains: upperCase(`${i18n.language}`) }],
            });
            try {
                const response = await axios.get(`${process.env.REACT_APP_STRAPI_DOMAIN}/configurations?${query}`);
                if (response.data) {
                    const target = head(response.data);
                    setNews(target);
                    if (target.content.replace(/<(.|\n)*?>/g, '').trim().length === 0 || hasSectionParam) {
                        setIsOpen(false);
                        setIsFetching(false);
                    } else if (!hasSectionParam) {
                        setIsOpen(true);
                        setIsFetching(false);
                    }
                }
            } catch (error) {
                console.error(error);
                setIsFetching(false);
                trackGaErr(error);
            }
        }
        getNews();
    }, [i18n.language]);

    return <Popup {...props} >
        <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
            <Dialog.Title
                as="h3"
                className="text-3xl font-medium leading-6 text-wsd mb-6"
            >
                {t('title', { ns: 'news' })}
            </Dialog.Title>
            <div className="lg:min-h-[150px] my-6 mt-2 border-dashed border-t-2 border-wsd pt-5 lg:text-lg h-fit">
                <Transition
                    show={!isFetching}
                    enter="transition-opacity duration-700"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div dangerouslySetInnerHTML={{ __html: sanitize(news?.content) }} /></Transition>
                {isFetching && <div className="justify-center flex my-32"><svg className="animate-spin -ml-1 mr-3 h-10 w-10 text-wsd" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg></div>}
            </div>

            <div className="mt-4 flex justify-center">
                <button onClick={() => closeModal()} className="max-w-sm h-14 w-full p-2 mt-6 text-2xl rounded-full bg-wsd text-white font-semibold shadow-md hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-yellow-500">
                    {t('close', { ns: 'news' })}
                </button>
            </div>
        </Dialog.Panel>
    </Popup>
}
import { useEffect, Fragment, useState, useRef } from 'react'
import { QRCodeCanvas } from 'qrcode.react';
import {
    useParams
} from "react-router-dom";
import axios from 'axios';
import { orderBy, get, head } from 'lodash'
import { useTranslation } from 'react-i18next';
import { useOutletContext } from "react-router-dom";
import html2canvas from "html2canvas";

import { trackGa, trackGaErr } from '../utils/ga'

export default function CheckIn(props) {
    const [setTitle] = useOutletContext();
    const { t, i18n } = useTranslation(['errorMessage', 'page', 'booking']);

    const lang = (lng) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        document.title = t(props.title, { ns: 'page' });
        document.documentElement.lang = i18n.language === 'en' ? 'en' : i18n.language === 'tc' ? 'zh-HK' : 'zh-CN';
        document.getElementsByTagName('meta')["keywords"].content = t('meta-keywords', { ns: 'page' });
        document.getElementsByTagName('meta')["description"].content = t('meta-desc', { ns: 'page' });
        setTitle(document.title);

        trackGa(i18n);
    }, [i18n.language])

    let { uid: groupId } = useParams();
    const [bookingData, setBookingData] = useState(null);
    const ref = useRef();

    useEffect(() => {
        async function getBooking() {
            try {
                const bookingResp = await axios.get(`${process.env.REACT_APP_STRAPI_DOMAIN}/participants/etk/${groupId}`);
                if (bookingResp.data) {
                    setBookingData(bookingResp.data);
                    const applicants = bookingResp.data.filter(x => x.isApplicant);
                    // set applicant's langauge preference
                    if (applicants.length) {
                        lang(head(applicants).lang);
                    }
                }
            } catch (error) {
                console.error(error);
                trackGaErr(error);
            }
        }
        getBooking();
    }, []);

    const download = async () => {
        const canvas = await html2canvas(ref.current);
        const image = canvas.toDataURL("image/png", 1.0);

        var link = document.createElement('a');
        link.download = 'e-ticket.png';
        link.href = image;
        link.click();
    }

    const routeMapUrl = `${process.env.REACT_APP_SHARE_DOMAIN}/pdf/Ex-SSPSR-Uphill-Route-Map.pdf`;

    return <Fragment>
        {bookingData && <div ref={ref} className="w-full bg-wsd py-4" id="ticket">
            <div className="flex justify-center p-4">
                <div className="w-full flex-col flex-none rounded-lg bg-white items-center pt-8">
                    <div className="flex flex-col justify-center px-10">
                        <div className="w-full flex justify-center">
                            <img className="w-24" src="/img/logo-ssp-reservoir.png" alt="Logo of Ex-Sham Shui Po Service Reservoir" />
                        </div>
                        <span className="mt-4 inline-block font-semibold text-center text-gray-700 text-2xl">{t('ticket.title', { ns: 'booking' })}</span>
                        <span className="mt-2 inline-block font-semibold text-center text-gray-500 text-md">{t('ticket.desc', { ns: 'booking' })}</span>
                        <span className="mt-2 inline-block font-semibold text-center text-gray-500 text-md">{t('ticket.inquiry-no', { ns: 'booking' })}: {bookingData[0].groupId}</span>
                        <span className={`px-2 mt-6 inline-block ${i18n.language === 'en' ? 'text-justify lg:text-center': 'text-left md:text-center'} text-gray-500 text-sm`}>{t('ticket.hint-1', { ns: 'booking' })}</span>
                        <span className={`px-2 mt-3 inline-block ${i18n.language === 'en' ? 'text-justify lg:text-center': 'text-left md:text-center'} text-gray-500 text-sm`}>{t('ticket.hint-2', { ns: 'booking' })} <a href={routeMapUrl} target="_blank" className="bg-white underline underline-offset-4 inline-block text-center text-blue-600 text-md font-medium">{t('ticket.map', { ns: 'booking' })}</a></span>
                        <span className={`px-2 mt-3 inline-block ${i18n.language === 'en' ? 'text-justify lg:text-center': 'text-left md:text-center'} text-gray-500 text-sm`}>{t('ticket.hint-3', { ns: 'booking' })}</span>
                        <span className={`px-2 mt-3 inline-block ${i18n.language === 'en' ? 'text-justify lg:text-center': 'text-left md:text-center'} text-gray-500 text-sm`}>{t('ticket.hint-4', { ns: 'booking' })}</span>
                        <span className={`px-2 mt-3 inline-block ${i18n.language === 'en' ? 'text-justify lg:text-center': 'text-left md:text-center'} text-gray-500 text-sm`}>{t('ticket.hint-5', { ns: 'booking' })}</span>
                        <span className={`px-2 mt-3 inline-block ${i18n.language === 'en' ? 'text-justify lg:text-center': 'text-left md:text-center'} text-gray-500 text-sm`}>{t('ticket.hint-6', { ns: 'booking' })}</span>
                        <span onClick={() => download()} id="download" className="underline underline-offset-4 mt-4 inline-block text-center text-blue-600 text-md font-medium">{t('ticket.download', { ns: 'booking' })}</span>
                        <span className="px-2 mt-3 inline-block text-center text-gray-500 text-[12px]">{t('ticket.download-hint', { ns: 'booking' })}</span>
                    </div>
                    <div className="flex justify-center">
                        <div className="flex flex-col py-2 px-4">
                            {orderBy(bookingData, ['isApplicant', 'ageGroup'], ['desc']).map((participant, idx) => {
                                return <div key={idx} className="p-8 my-4 max-w-2xl w-full border-2 border-gray-300 rounded-3xl flex flex-none items-center px-1">
                                    <div className="flex flex-col mx-6 w-full text-xl lg:text-2xl">
                                        {participant.ageGroup === 'CHILD' && <span className="inline-block font-bold text-center text-wsd text-md"><img className="w-20 inline-block" src="/img/boy2.png" alt="Ticket For Child" /></span>}
                                        {participant.ageGroup === 'ADULT' && <span className="inline-block font-bold text-center text-gray-600 text-md"><img className="w-20 inline-block" src="/img/man2.png" alt="Ticket For Adult"/></span>}

                                        <span className="mt-4 inline-block font-bold text-center text-wsd text-md">{t('ticket.ref-no', { ns: 'booking' })}</span>
                                        <span className="inline-block font-bold text-center text-gray-600 text-md">{participant.bookingReferenceId}</span>

                                        <span className="mt-6 inline-block font-bold text-center text-wsd text-md">{t('ticket.visit-date', { ns: 'booking' })}</span>
                                        <span className="inline-block text-center text-gray-600 text-md">{participant.session.formattedDate}</span>
                                        <span className="inline-block text-center text-gray-600 text-md">
                                            {participant.session.formattedStartAt} - {participant.session.formattedEndAt}
                                        </span>

                                        <span className="mt-6 inline-block font-bold text-center text-wsd text-md">{t('ticket.id-desc-1', { ns: 'booking' })}</span>
                                        <span className="inline-block font-bold text-center text-wsd text-md">{t('ticket.id-desc-2', { ns: 'booking' })}</span>
                                        <span className="inline-block text-center font-bold text-gray-600 text-md">{get(participant, 'idType', 'HKID') === 'HKID' ? participant.hkid : participant.travelDocumentId}</span>

                                        <span className="mt-6 inline-block font-bold text-center text-wsd text-md">{t('ticket.phone', { ns: 'booking' })}</span>
                                        <span className="inline-block font-bold text-center text-gray-600 text-md">{participant.phoneNumber || '-'}</span>

                                        <div className="flex justify-center mt-6">
                                            <QRCodeCanvas fgColor={participant.ageGroup === 'ADULT' ? '#000000' : '#2a67b4'} value={participant.bookingReferenceId} size={220} level="H" />
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>



            </div>
        </div>}
    </Fragment>
}
import { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import qs from 'qs'
import { sanitize } from 'dompurify';
import { capitalize, head, isEmpty } from 'lodash';
import { format, isPast, isBefore, set } from 'date-fns'
import { useTranslation } from 'react-i18next';

export default function Detector() {
    const [detectorConfig, setDetectorConfig] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const { t, i18n } = useTranslation(['intro', 'errorMessage', 'page']);

    useEffect(() => {
        async function getDetectorConfig() {
            setIsFetching(true);
            const query = qs.stringify({
                _where: [{ name_eq: `DETECTOR` }],
            });
            try {
                const response = await axios.get(`${process.env.REACT_APP_STRAPI_DOMAIN}/configurations?${query}`);
                if (response.data) {
                    const target = head(response.data);
                    setDetectorConfig(JSON.parse(target.content));
                    setIsFetching(false)
                }
            } catch (error) {
                console.error(error);
                setIsFetching(false);
            }
        }
        getDetectorConfig();
    }, []);

    const detectorDates = [
        {
            session: 'am',
            displayTime: '09:00 - 12:00',
            startAtHour: { hours: 9, minutes: 0, seconds: 0 },
            endAtHour: { hours: 12, minutes: 0, seconds: 0 }
        },
        {
            session: 'noon',
            displayTime: '12:00 - 14:00',
            startAtHour: { hours: 12, minutes: 0, seconds: 0 },
            endAtHour: { hours: 14, minutes: 0, seconds: 0 }
        },
        {
            session: 'pm',
            displayTime: '14:00 - 17:00',
            startAtHour: { hours: 14, minutes: 0, seconds: 0 },
            endAtHour: { hours: 17, minutes: 0, seconds: 0 }
        }
    ]

    const defaultDetectorDates = [
        {
            session: 'closed',
            displayTime: ' - '
        }
    ]

    const defaultDetectorText = {
        'en': 'Closed',
        'tc': '暫不開放',
        'sc': '暂不开放'
    }

    const selectDetectorDates = () => {
        const dates = detectorDates.filter(o => {
            const startDate = set(new Date(), o.startAtHour);
            const endDate = set(new Date(), o.endAtHour);
            // console.log(isBefore(new Date(), endDate))
            return isPast(startDate) && isBefore(new Date(), endDate)
        })

        if (!dates.length) {
            return defaultDetectorDates;
        }
        return dates;
    }

    const displayDetector = (session) => {
        console.log(session, detectorConfig)
        const getResult = (resultText) => {
            console.log(resultText)
            return isEmpty(resultText) ? defaultDetectorText[i18n.language]: resultText
        }
        if (detectorConfig) {
            if (!!detectorConfig.holidayStartDate && !!detectorConfig.holidayEndDate) {
                const hStartDate = new Date(detectorConfig.holidayStartDate);
                const hEndDate = new Date(detectorConfig.holidayEndDate);
                // if within holiday setup
                if (isPast(hStartDate) && isBefore(new Date(), hEndDate)) {
                    return getResult(detectorConfig[`${session}InHoliday${capitalize(i18n.language)}`])
                }
            }
            return getResult(detectorConfig[`${session}${capitalize(i18n.language)}`])
        }
        return;
    }

    return <Fragment>
        <div>
            <div className="mt-6 flex justify-center">
                <div className="max-w-xl w-full flex px-4 justify-between h-12 mt-6 border-y-2">
                    <div className="flex flex-none items-center px-1">
                        <img className="w-8" src="/img/enroll-user.png" alt="" />
                        <span className="ml-6 inline-block text-md md:text-xl">{t('tab.quota', { ns: 'intro' })}</span>
                    </div>
                </div>
            </div>

            <div className="mx-auto md:w-3/4 mt-10 mb-24 px-6 md:px-4">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="rounded-tl-lg text-center bg-wsd text-white py-3.5 pl-4 pr-3 text-left text-xl md:text-xl font-semibold">{t('table.time', { ns: 'intro' })}</th>
                            <th scope="col" className="rounded-tr-lg bg-wsd text-white py-3.5 pl-4 pr-3 text-left text-xl md:text-2xl font-semibold">
                                <div className="flex flex-col text-center">
                                    <span className="inline-block text-md">{format(new Date, 'MM / dd')}</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {selectDetectorDates().map((x) => (
                            <tr>
                                <td className="w-1/2 border-b-2 border-gray-100 rounded-bl-lg bg-gray-50 text-center p-2 md:p-10 text-sm text-gray-500">
                                    <div className="font-medium flex justify-center items-center mx-2 text-xl md:text-2xl text-center">
                                        <span className="inline-block">{x.displayTime}</span>
                                    </div>
                                </td>
                                <td className="w-1/2 border-b-2 border-gray-100 rounded-br-lg bg-gray-50 text-center p-5 md:p-10 text-sm text-gray-500">
                                    <div className="font-medium flex justify-center items-center mx-2 text-xl md:text-2xl text-center">
                                        <span className="inline-block"><div dangerouslySetInnerHTML={{ __html: sanitize(displayDetector(x.session)) }} /></span>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>

    </Fragment>
}
import './App.css';
import { useState } from 'react'
import Header from './components/Header'
import { Outlet } from "react-router-dom";

import Footer from './components/Footer';

function App() {
  const [title, setTitle] = useState('');
  const [selectedSession, setSelectedSession] = useState(null);

  return (
    <div className="w-screen lg:container lg:mx-auto">
      <Header title={title} selectedSession={selectedSession} />
      <Outlet context={[setTitle, selectedSession, setSelectedSession]} />
      <Footer />
    </div>
  );
}

export default App;

import DatePicker, { registerLocale } from 'react-datepicker'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { set } from 'date-fns'
import { useTranslation } from 'react-i18next';
import { formatInTimeZone } from 'date-fns-tz';

import { sessionFilter } from '../utils/common'
import { zhHK, zhCN, enGB } from 'date-fns/esm/locale'

registerLocale('tc', zhHK)
registerLocale('sc', zhCN)
registerLocale('en', enGB)


export default function TailwindDatePicker({ selectedDate, setSelectedDate, setSessionGoingToSelect, placeHolder, sessions, numberOfParticipant }) {
    const { i18n } = useTranslation();

    const langMap = {
        'en': enGB,
        'sc': zhCN,
        'tc': zhHK
    }

    const toBeIncludedDates = sessionFilter(sessions, numberOfParticipant).map(session => {
        // use zonedDisplayTime as the date option for user to select
        // fixing format is not going to work as date comparison is purely using date object without timezone / offset
        // so twisting the date object as zoned time
        const newDate = set(new Date(session.zonedDisplayTime), { minute: 0, second: 0 })
        return newDate;
    })

    const setDate = (date) => {
        setSelectedDate(date);
        setSessionGoingToSelect(null);
    }

    // no need to alter the format of react-datepicker as its already zoned time. Altering the format to zoned time format in react-datepicker could result in wrong time display
    return <DatePicker
        name="date"
        id="date"
        selected={selectedDate}
        onChange={(date) => setDate(date)}
        minDate={new Date()}
        locale={i18n.language}
        includeDates={toBeIncludedDates}
        dateFormat="yyyy - MM - dd"
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        popperClassName="react-datepicker-right"
        placeholderText={placeHolder}
        renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
        }) => (
            <div className="flex items-center justify-between px-2 py-2">
                <span className="text-lg text-gray-600">
                    {formatInTimeZone(date, 'Asia/Hong_Kong', 'LLLL yyyy', { locale: langMap[i18n.language] })}
                </span>

                <div className="space-x-2">
                    <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                        type="button"
                        className={`
                                ${prevMonthButtonDisabled && 'cursor-not-allowed opacity-50'}
                                inline-flex p-1 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                            `}
                    >
                        <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
                    </button>

                    <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                        type="button"
                        className={`
                                ${nextMonthButtonDisabled && 'cursor-not-allowed opacity-50'}
                                inline-flex p-1 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                            `}
                    >
                        <ChevronRightIcon className="w-5 h-5 text-gray-600" />
                    </button>
                </div>
            </div>
        )}
    />
}
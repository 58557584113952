import { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import CancelPopup from './CancelPopup'
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string, number } from "yup";
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import { useOutletContext } from "react-router-dom";

import ErrorMessage from './ErrorMessage'
import Alert from './Alert';
import { trackGa, trackGaErr } from '../utils/ga'

const getBookingCancelSchema = () =>
    object().shape({
        'bookingReferenceId': string().required(),
        'phoneNumber': number().nullable(true).transform((v, o) => o === '' ? null : v)
    })

const ALERT_DISPLAY_TIME_IN_MS = 5000;

export default function CancelBooking(props) {
    const [setTitle] = useOutletContext();
    const { t, i18n } = useTranslation(['errorMessage', 'page', 'booking']);

    useEffect(() => {
        document.title = t(props.title, { ns: 'page' });
        document.documentElement.lang = i18n.language === 'en' ? 'en' : i18n.language === 'tc' ? 'zh-HK' : 'zh-CN';
        document.getElementsByTagName('meta')["keywords"].content = t('meta-keywords', { ns: 'page' });
        document.getElementsByTagName('meta')["description"].content = t('meta-desc', { ns: 'page' });
        setTitle(document.title);

        trackGa(i18n);
    }, [i18n.language])

    const [isOpen, setIsOpen] = useState(false);
    const [participantToCancel, setParticipantToCancel] = useState(null);

    const [alertStatus, setAlertStatus] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(getBookingCancelSchema()),
    });

    const onSubmitForm = (data) => {
        async function participantLookup() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_STRAPI_DOMAIN}/participants/bookingRefId/${data.bookingReferenceId}/phone/${data.phoneNumber || '0'}`)
                if (response.data) {
                    setParticipantToCancel(response.data)
                }
            } catch (error) {
                displayAlert(error);
                trackGaErr(error);
            }
            return null;
        }
        participantLookup();
    };

    const displayAlert = (error) => {
        console.error(error);
        const errMsg = error?.response?.data?.message;
        if (errMsg) {
            setAlertStatus('FAILED');
            setAlertText(t([errMsg, 'unspecific'], { ns: 'errorMessage' }));
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setAlertText('');
            }, ALERT_DISPLAY_TIME_IN_MS)
        }
    }

    return (
        <>
            <Alert status={alertStatus} text={alertText} setShow={setShowAlert} show={showAlert} />
            <div className="mb-6">
                <CancelPopup
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    participant={participantToCancel}
                    reset={reset}
                    displayAlert={displayAlert}
                />

                <div className="flex justify-center">
                    <div className="max-w-3xl w-full my-6">
                        <form onSubmit={handleSubmit(onSubmitForm)}>
                            <div className="p-6 pb-8 rounded-3xl mx-4 bg-wsd-cancel">
                                <div className="flex flex-1 justify-center items-center px-1">
                                    <div className="py-1 border-1 rounded-full text-2xl text-wsd-form">
                                        {t('cancel.title', { ns: 'booking' })}
                                    </div>
                                </div>
                                <div className="mt-8">
                                    <label for="referenceNumber" className="ml-1 block text-md font-medium text-gray-600">{t('cancel.ref-no', { ns: 'booking' })}</label>
                                    <p className="ml-1 mt-1 text-sm text-gray-600">{t('cancel.ref-no-hint', { ns: 'booking' })}</p>
                                    <div className="mt-3">
                                        <input type="text" id="referenceNumber" {...register("bookingReferenceId")} className="text-lg shadow-md focus:outline-none focus:ring-2 focus:ring-yellow-500 block w-full h-14 p-4 rounded-lg" placeholder={t('cancel.enter-ref-no', { ns: 'booking' })} />
                                        {errors['bookingReferenceId'] && <ErrorMessage error={errors['bookingReferenceId']?.message} />}
                                    </div>
                                </div>
                                <div className="mt-10">
                                    <label for="phoneNumber" className="ml-1 block text-md font-medium text-gray-600">{t('cancel.phone', { ns: 'booking' })}</label>
                                    <p className="ml-1 mt-1 text-sm text-gray-600">{t('cancel.phone-hint', { ns: 'booking' })}</p>
                                    <div className="mt-3">
                                        <input type="text" id="phoneNumber" {...register("phoneNumber")} className="text-lg shadow-md focus:outline-none focus:ring-2 focus:ring-yellow-500 block w-full h-14 p-4 rounded-lg" placeholder={t('cancel.enter-phone', { ns: 'booking' })} />
                                        {errors['phoneNumber'] && <ErrorMessage error={errors['phoneNumber']?.message} />}
                                    </div>
                                </div>
                                <div className="flex justify-center mt-14">
                                    <button type="submit" className="lg:max-w-[400px] bg-wsd-form w-full p-2 h-14 text-2xl rounded-full text-white font-semibold shadow-md hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-yellow-500">
                                        {t('cancel.submit', { ns: 'booking' })}
                                    </button>
                                </div>
                                <div className="flex justify-center mt-6">
                                    <Link to="/" className="text-center lg:max-w-[400px] bg-gray-500 w-full p-3 text-2xl rounded-full text-white font-semibold shadow-md hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-500">
                                        {t('cancel.back', { ns: 'booking' })}
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div >
        </>
    );
}
export const yupLocale = {
    mixed: {
        default: {
            key: 'validations.invalid',
        },
        required: {
            key: 'validations.required',
        },
        notType: ({ type }) => ({
            key: 'validations.invalidType',
            values: { type },
        }),
    },
    string: {
        email: {
            key: 'validations.email',
        },
        min: ({ min }) => ({
            key: 'validations.stringMin',
            values: { min },
        }),
        max: ({ max }) => ({
            key: 'validations.stringMax',
            values: { max },
        }),
        matches: {
            key: 'validations.matches',
        },
        length: ({ length }) => ({
            key: 'validations.stringLen',
            values: { length }
        }),
    },
    number: {
        min: ({ min }) => ({
            key: 'validations.numberMin',
            values: { min },
        }),
        max: ({ max }) => ({
            key: 'validations.numberMax',
            values: { max },
        }),
        len: {
            key: 'validations.length',
        }
    }
};